import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Divider, LoadingPlaceholder, Space, useStyles2 } from '@grafana/ui';

import { createPipeline, updatePipeline, useMutation } from '@grafana-cloud/fleet-management-api';
import { Alert } from '@grafana-cloud/ui';
import { useAppSelector } from 'feature/common/store';

import { WizardHeader } from '../../../common/components/Wizard/WizardHeader';
import { useEditConfigurationSteps } from '../../hooks/useEditConfigurationSteps';
import { DEFINE_CONFIGURATION, MATCH_TO_COLLECTORS } from '../../store/EditConfigurationSlice';

import { ConfigurationForm } from './ConfigurationForm';
import { MatchToCollectors } from './MatchToCollectors';
import { SaveConfigurationModal } from './SaveConfigurationModal';

export const ConfigurationWizard: React.FC<{
  mutation: typeof createPipeline | typeof updatePipeline;
  onClose: () => void;
  title: string;
}> = ({ mutation, onClose, title }) => {
  const styles = useStyles2(getStyles);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const { data: configuration, steps, tested, annotations } = useAppSelector((state) => state.editConfiguration);
  const {
    step: activeStep,
    hasNextStep,
    hasPrevStep,
    nextStep,
    prevStep,
    resetWizard,
    setTested,
  } = useEditConfigurationSteps();

  const { mutate, error, isPending } = useMutation(mutation);

  function onTest() {
    mutate(
      {
        pipeline: configuration,
        validateOnly: true,
      },
      {
        onSuccess: setTested,
      }
    );
  }

  function onConfigurationSave() {
    setShowSaveModal(false);
    onClose();
  }

  useEffect(() => {
    resetWizard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WizardHeader steps={steps} activeStep={activeStep} />
      <Space v={5} layout="block" />
      {activeStep === DEFINE_CONFIGURATION && (
        <>
          <ConfigurationForm title={title} />
          <Button onClick={onTest} variant="secondary" disabled={isPending} className={styles.testButton}>
            Test
          </Button>
          {isPending && <LoadingPlaceholder text="Testing..." />}
          {tested && <Alert status="success">Configuration syntax is correct</Alert>}
        </>
      )}
      {activeStep === MATCH_TO_COLLECTORS && <MatchToCollectors />}
      <Space v={2} layout="block" />

      {annotations.length > 0
        ? annotations.map((annotation, index) => (
            <Alert status="error" key={index}>
              {annotation.row}:{annotation.column} - {annotation.text}
            </Alert>
          ))
        : error && <Alert status="error">{error.message}</Alert>}

      <Space v={3} layout="block" />
      <Divider />
      <div className={styles.buttons}>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {hasPrevStep() && (
          <Button variant="secondary" onClick={prevStep}>
            Back
          </Button>
        )}
        <Button
          disabled={!tested}
          variant={hasNextStep() ? 'secondary' : 'primary'}
          onClick={() => setShowSaveModal(true)}
        >
          Save
        </Button>
        {hasNextStep() && (
          <Button disabled={!tested} onClick={nextStep}>
            Next
          </Button>
        )}
        {showSaveModal && (
          <SaveConfigurationModal
            onSave={onConfigurationSave}
            onClose={() => setShowSaveModal(false)}
            mutation={mutation}
          />
        )}
      </div>
    </>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    buttons: css({
      display: 'flex',
      justifyContent: 'flex-start',
      gap: theme.spacing(2),
    }),
    testButton: css({
      marginTop: theme.spacing(-5),
    }),
  };
}
