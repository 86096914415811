import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React from 'react';

import { Column, Stack } from '@grafana/ui';

import { Collector } from '@grafana-cloud/fleet-management-api';

import { columns } from '../../CollectorListTable/TableColumns';

import { EditButtonSelectionItem } from './EditButtonSelectionItem';

type Props = {
  setSelectableColumns: React.Dispatch<React.SetStateAction<Array<Column<Collector>>>>;
  selectableColumns: typeof columns;
};

export const EditButtonSelection = ({ setSelectableColumns, selectableColumns }: Props) => {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || !active) {
      return;
    }

    if (active.id !== over?.id) {
      setSelectableColumns((items) => {
        const columnIds = items.map((item) => item.id);
        const oldIndex = columnIds.indexOf(active.id);
        const newIndex = columnIds.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext items={selectableColumns} strategy={verticalListSortingStrategy}>
        <Stack direction="column">
          {selectableColumns.map((column) => (
            <EditButtonSelectionItem
              key={column.id}
              column={column}
              selectableColumns={selectableColumns}
              setSelectableColumns={setSelectableColumns}
            />
          ))}
        </Stack>
      </SortableContext>
    </DndContext>
  );
};
