import React from 'react';

import { Button, Stack } from '@grafana/ui';

import { generateAttributeId } from 'feature/common/utils/uuid';

type Props = {
  setAttributesToChangeCurrent: (attributes: Array<{ id: string; name: string; value: string }>) => void;
  setAttributesToChangeNew: (attributes: Array<{ id: string; name: string; value: string }>) => void;
  attributesToChangeCurrent: Array<{ id: string; name: string; value: string }>;
  attributesToChangeNew: Array<{ id: string; name: string; value: string }>;
};

export const AttributeSelectAddButtonReplace = ({
  setAttributesToChangeCurrent,
  setAttributesToChangeNew,
  attributesToChangeCurrent,
  attributesToChangeNew,
}: Props) => {
  const onAddAttribute = () => {
    setAttributesToChangeCurrent([...attributesToChangeCurrent, { id: generateAttributeId(), name: '', value: '' }]);
    setAttributesToChangeNew([...attributesToChangeNew, { id: generateAttributeId(), name: '', value: '' }]);
  };
  const addDisabled =
    attributesToChangeCurrent.some((attribute) => attribute.name === '' || attribute.value === '') ||
    attributesToChangeNew.some((attribute) => attribute.name === '' || attribute.value === '');

  return (
    <Stack>
      <Button
        icon="plus"
        onClick={onAddAttribute}
        variant="secondary"
        disabled={addDisabled}
        tooltip={addDisabled ? 'Ensure all key value pairs are not empty' : undefined}
      />
    </Stack>
  );
};
