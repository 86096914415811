import React from 'react';

import { Select } from '@grafana/ui';

import { AttributeOperator } from 'feature/common/types/Attributes';

const options = [
  {
    label: '=',
    value: AttributeOperator.Equals,
  },
  {
    label: '!=',
    value: AttributeOperator.NotEquals,
  },
];

export const AttributeOperatorSelect: React.FC<{
  attributeOperator: AttributeOperator;
  onChange: (attributeName: AttributeOperator) => void;
}> = ({ attributeOperator, onChange }) => {
  return (
    <>
      <Select
        onChange={(value) => onChange(value.value || AttributeOperator.Equals)}
        value={attributeOperator}
        options={options}
      />
    </>
  );
};
