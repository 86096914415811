import React, { JSX } from 'react';

import { FleetManagementApiProvider } from '@grafana-cloud/fleet-management-api';
import { GrafanaApiProvider } from '@grafana-cloud/grafana-api';
import { GrafanaComApiProvider } from '@grafana-cloud/grafana-com-api';
import { IntegrationsApiProvider } from '@grafana-cloud/integrations-api';
import { GCOM_URL, PLUGIN_PROXY_URL } from 'feature/common/api/paths';
import { FLEET_MANAGEMENT_API_URL } from 'feature/common/const/ApiUrls';

export function ApiProviders({ children }: { children: JSX.Element }) {
  return (
    <GrafanaApiProvider
      config={{
        baseApiUrl: 'api',
      }}
    >
      <IntegrationsApiProvider
        config={{
          baseApiUrls: {
            admin: PLUGIN_PROXY_URL + '/integrations-api-admin',
            editor: PLUGIN_PROXY_URL + '/integrations-api-editor',
          },
        }}
      >
        <FleetManagementApiProvider
          config={{
            baseApiUrl: FLEET_MANAGEMENT_API_URL,
          }}
        >
          <GrafanaComApiProvider config={{ baseApiUrl: GCOM_URL }}>{children}</GrafanaComApiProvider>
        </FleetManagementApiProvider>
      </IntegrationsApiProvider>
    </GrafanaApiProvider>
  );
}
