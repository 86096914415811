import { AppPlugin } from '@grafana/data';

import { addComponent } from '@grafana-cloud/ui';
import {
  CollectorInstallationInstructionsExtension,
  CollectorInstallationInstructionsExtensionProps,
} from 'extensions/CollectorInstallationInstructionsExtension';
import { AppJSONData } from 'feature/common/types/AppJSONData';

import { App } from './feature/app/components';
import { ComingSoon } from './feature/app/components/ComingSoon';
import { isCollectorAppEnabled } from './feature/common/utils/featureToggles';

export const plugin = new AppPlugin<AppJSONData>().setRootPage(isCollectorAppEnabled() ? App : ComingSoon);

addComponent<CollectorInstallationInstructionsExtensionProps>(plugin, {
  title: 'Collector Installation Instructions',
  description: 'UI for displaying collector installation instructions.',
  targets: 'grafana/grafana-collector-app/collector-installation-instructions/v1',
  component: CollectorInstallationInstructionsExtension,
});
