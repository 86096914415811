import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Badge, useStyles2 } from '@grafana/ui';

type Props = {
  attributes: { [key: string]: string };
};

export const AttributeBadges = ({ attributes }: Props) => {
  const styles = useStyles2(getStyles);

  return Object.entries(attributes).map(([key, value]) => {
    const badgeText = `${key}=${value}`;
    return <Badge className={styles.attributes} text={badgeText} color="blue" key={badgeText}></Badge>;
  });
};

const getStyles = (theme: GrafanaTheme2) => ({
  attributes: css({
    marginRight: theme.spacing(0.5),
  }),
});
