import { useEffect, useState } from 'react';

import { AlloyEditorAnnotations } from '../types/AlloyEditorAnnotation';
import { AlloyEditorMarker } from '../types/AlloyEditorMarker';

const { Parser } = require('../utils/AlloyValidator');

export function useValidationResults(value: string, onValidate: (annotations: AlloyEditorAnnotations[]) => void) {
  const [annotations, setAnnotations] = useState<AlloyEditorAnnotations[]>([]);
  const [markers, setMarkers] = useState<AlloyEditorMarker[]>([]);
  useEffect(() => {
    if (value) {
      const result = Parser.ParseString(value);
      const { HasErrors, $array } = result?.[1];
      if (typeof HasErrors === 'function') {
        const annotations: AlloyEditorAnnotations[] = $array.map((error: any) => ({
          row: error.StartPos.Line - 1,
          column: error.StartPos.Column,
          text: error.Message,
          type: 'error',
        }));

        const markers: AlloyEditorMarker[] = $array.map((error: any) => ({
          startRow: error.StartPos.Line - 1,
          startCol: error.StartPos.Column - 1,
          endRow: error.EndPos.Line ? error.EndPos.Line - 1 : error.StartPos.Line - 1,
          endCol: error.EndPos.Column ? error.EndPos.Column : error.StartPos.Column,
          className: 'ace_highlight-marker alloy-text-error',
          type: 'text',
        }));

        setAnnotations(annotations);
        setMarkers(markers);

        onValidate(annotations);
      } else {
        setAnnotations([]);
        setMarkers([]);
        onValidate([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return { annotations, markers };
}
