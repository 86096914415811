import React from 'react';

import {
  EmbeddedScene,
  PanelBuilders,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObject,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';

import { getHealthVariables, getHealthVariablesQuery, HealthVariableNames } from './healthQueryVariables';

interface HeaderState extends SceneObjectState {
  title: string;
}

class Header extends SceneObjectBase<HeaderState> {
  static Component = (props: SceneComponentProps<Header>) => {
    const { title } = props.model.useState();
    return <h2>{title}</h2>;
  };
}

interface SceneContainerState extends SceneObjectState {
  style: React.CSSProperties;
  children: SceneObject[];
}

class SceneContainer extends SceneObjectBase<SceneContainerState> {
  static Component = (props: SceneComponentProps<SceneContainer>) => {
    const { style, children } = props.model.useState();
    return (
      <div style={style}>
        {children.map((item: SceneObject) => {
          const Component = item.Component;
          return <Component key={item.state.key} model={item} />;
        })}
      </div>
    );
  };
}

export const healthOverivewScene = (
  allowedVariables: HealthVariableNames,
  filters: Record<string, string | null> = {}
) => {
  const variablesQuery = getHealthVariablesQuery(allowedVariables, filters);

  const overviewQueryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: 'grafanacloud-prom',
    },
    queries: [
      {
        refId: 'A',
        format: 'table',
        instant: true,
        expr: `count by (instance, version) (alloy_build_info{${variablesQuery}})`,
      },
    ],
  });

  const targetSyncQueryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: 'grafanacloud-prom',
    },
    queries: [
      {
        refId: 'A',
        format: 'time_series',
        expr: `sum(rate(prometheus_target_sync_length_seconds_sum{${variablesQuery}}[$__rate_interval])) by (instance, scrape_job)`,
        intervalFactor: 2,
        legendFormat: '{{instance}}/{{scrape_job}}',
      },
    ],
  });

  const scrapeIntervalQueryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: 'grafanacloud-prom',
    },
    queries: [
      {
        refId: 'A',
        format: 'time_series',
        expr: `rate(prometheus_target_interval_length_seconds_sum{${variablesQuery}}[$__rate_interval]) / rate(prometheus_target_interval_length_seconds_count{${variablesQuery}}[$__rate_interval])`,
        intervalFactor: 2,
        legendFormat: '{{instance}} {{interval}} configured',
      },
    ],
  });

  const scrapeFailuresQueryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: 'grafanacloud-prom',
    },
    queries: [
      {
        refId: 'A',
        format: 'time_series',
        expr: `sum by (job) (rate(prometheus_target_scrapes_exceeded_sample_limit_total{${variablesQuery}}[$__rate_interval]))`,
        intervalFactor: 2,
        legendFormat: 'exceeded sample limit: {{job}}',
      },
      {
        refId: 'B',
        format: 'time_series',
        expr: `sum by (job) (rate(prometheus_target_scrapes_sample_duplicate_timestamp_total{${variablesQuery}}[$__rate_interval]))`,
        intervalFactor: 2,
        legendFormat: 'duplicate timestamp: {{job}}',
      },
      {
        refId: 'C',
        format: 'time_series',
        expr: `sum by (job) (rate(prometheus_target_scrapes_sample_out_of_bounds_total{${variablesQuery}}[$__rate_interval]))`,
        intervalFactor: 2,
        legendFormat: 'out of bounds: {{job}}',
      },
      {
        refId: 'D',
        format: 'time_series',
        expr: `sum by (job) (rate(prometheus_target_scrapes_sample_out_of_order_total{${variablesQuery}}[$__rate_interval]))`,
        intervalFactor: 2,
        legendFormat: 'out of order: {{job}}',
      },
    ],
  });

  const appendedSamplesQueryRunner = new SceneQueryRunner({
    datasource: {
      type: 'prometheus',
      uid: 'grafanacloud-prom',
    },
    queries: [
      {
        refId: 'A',
        format: 'time_series',
        expr: `sum by (job, instance_group_name) (rate(prometheus_remote_write_wal_samples_appended_total{${variablesQuery}}[$__rate_interval]))`,
        intervalFactor: 2,
        legendFormat: '{{job}} {{instance_group_name}}',
      },
    ],
  });

  const overviewPanel = PanelBuilders.table()
    .setTitle('Running Instances')
    .setData(overviewQueryRunner)
    .setOverrides((b) => b.matchFieldsWithName('Value').overrideCustomFieldConfig('hidden', true))
    .setOverrides((b) => b.matchFieldsWithName('Time').overrideCustomFieldConfig('hidden', true))
    .setOverrides((b) => b.matchFieldsWithName('instance').overrideDisplayName('Instance'))
    .setOverrides((b) => b.matchFieldsWithName('version').overrideDisplayName('Version'));

  const targetSyncPanel = PanelBuilders.timeseries().setTitle('Target Sync').setData(targetSyncQueryRunner);

  const scrapeIntervalPanel = PanelBuilders.timeseries()
    .setTitle('Average Scrape Interval Duration')
    .setData(scrapeIntervalQueryRunner);

  const scrapeFailuresPanel = PanelBuilders.timeseries().setTitle('Scrape Failures').setData(scrapeFailuresQueryRunner);

  const appendedSamplesPanel = PanelBuilders.timeseries()
    .setTitle('Appended Samples')
    .setData(appendedSamplesQueryRunner);

  return new EmbeddedScene({
    $variables: new SceneVariableSet({
      variables: getHealthVariables(allowedVariables),
    }),
    $timeRange: new SceneTimeRange({ from: 'now-4h', to: 'now' }),
    controls: [
      new SceneContainer({
        style: {
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        },
        children: [
          new VariableValueSelectors({}),
          new SceneRefreshPicker({ isOnCanvas: true }),
          new SceneTimePicker({ isOnCanvas: true }),
        ],
      }),
    ],
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        new Header({ title: 'Overview' }),
        new SceneFlexItem({
          width: '100%',
          minHeight: 200,
          body: overviewPanel.build(),
        }),
        new Header({ title: 'Prometheus Discovery' }),
        new SceneFlexLayout({
          children: [
            new SceneFlexItem({
              width: '100%',
              height: 300,
              body: targetSyncPanel.build(),
            }),
          ],
        }),
        new Header({ title: 'Prometheus Retrieval' }),
        new SceneFlexLayout({
          wrap: 'wrap',
          children: [
            new SceneFlexItem({
              width: '32%',
              height: 300,
              body: scrapeIntervalPanel.build(),
              md: { width: '100%' },
            }),
            new SceneFlexItem({
              width: '32%',
              height: 300,
              body: scrapeFailuresPanel.build(),
              md: { width: '100%' },
            }),
            new SceneFlexItem({
              width: '32%',
              height: 300,
              body: appendedSamplesPanel.build(),
              md: { width: '100%' },
            }),
          ],
        }),
      ],
    }),
  });
};
