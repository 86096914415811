import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { PanelContainer, Stack, useStyles2 } from '@grafana/ui';

import { WizardSteps } from '../../types/Wizard';

import { WizardHeaderStep } from './WizardHeaderStep';

export const WizardHeader = ({ steps, activeStep }: { steps: WizardSteps; activeStep: number }) => {
  const styles = useStyles2(getStyles);
  const stepValues = Object.values(steps);
  return (
    <PanelContainer className={styles.ct}>
      <Stack direction="row" wrap="wrap" alignItems="center" justifyContent={'center'} gap={1}>
        {stepValues.map((step, index) => (
          <>
            <WizardHeaderStep step={step} index={index} active={activeStep === index} />
            {index !== stepValues.length - 1 && <span className={styles.spacer}>.....</span>}
          </>
        ))}
      </Stack>
    </PanelContainer>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    ct: css({
      padding: theme.spacing(1.5),
    }),
    spacer: css({
      top: '-4px',
      position: 'relative',
    }),
  };
}
