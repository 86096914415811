import { css } from '@emotion/css';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';

import { HomeDiagramSection } from 'feature/home/components/HomeDiagramSection';
import { HomeListSection } from 'feature/home/components/HomeListSection';
import { HomeStartSection } from 'feature/home/components/HomeStartSection';
import { HomeStepsSection } from 'feature/home/components/HomeStepsSection';
import { HomeTitleSection } from 'feature/home/components/HomeTitleSection';

import { track } from '../../common/utils/track';

export const Home: React.FC = () => {
  const styles = useStyles2(getStyles);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/a/grafana-collector-app') {
      track('grafana_collector_app_home_rendered');
    }
  }, [location]);

  return (
    <PluginPage
      pageNav={{
        text: '',
      }}
    >
      <HomeTitleSection />
      <HomeStartSection />
      <HomeStepsSection />
      <hr className={styles.divider} />
      <HomeListSection />
      <HomeDiagramSection />
    </PluginPage>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  divider: css({
    borderTop: `1px solid ${theme.colors.border}`,
    width: '100%',
    margin: 0,
    marginBottom: theme.spacing(6),
  }),
});
