import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

export const HomeListSection: React.FC = () => {
  const styles = useStyles2(getStyles);

  return (
    <section className={styles.section}>
      <h2 className={styles.title}>What makes Alloy unique</h2>
      <div className={styles.subSection}>
        <div>
          <ul>
            <li className={styles.li}>
              Only Alloy offers native pipelines for OTel, Prometheus, Pyroscope, and Loki telemetry formats, supporting
              metrics, logs, traces, and profiles.
            </li>
            <li>
              Alloy can be used with a telemetry backend from Gratana Cloud or any other compatible backend from any
              other vendor.
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li className={styles.li}>
              Alloy has built in enterprise features, including native clustering for scale, security with HCP vault or
              Prometheus clusters, and debugging capabilities.
            </li>
            <li>Alloy can also be combined into a hybrid system of multiple collectors.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  section: css({
    textAlign: 'center',
  }),
  title: css({
    display: 'inline',
    borderBottom: `3px solid`,
    borderImage: `linear-gradient(270deg, #F55F3E 0%, #F83 100%) 1`,
  }),
  subSection: css({
    maxWidth: '1000px',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    gap: theme.spacing(6),
    textAlign: 'left',
    '@media screen and (max-width: 1300px)': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      maxWidth: 400,
    },
  }),
  li: css({
    marginBottom: theme.spacing(4),
  }),
});
