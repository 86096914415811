import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, useTheme2 } from '@grafana/ui';

import { alloyDiagramDark, alloyDiagramLight, one, three, two } from 'img';

export const HomeDiagramSection: React.FC = () => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();

  const items = [
    {
      img: one,
      title: 'Collect',
      description: (
        <>
          <p>
            Grafana Alloy uses more than 120 components to collect telemetry data from applications, databases, and
            OpenTelemetry collectors. Alloy supports collection using multiple ecosystems, including OpenTelemetry and
            Prometheus.
          </p>
          <p>
            Telemetry data can be either pushed to Grafana Alloy, or Grafana Alloy can pull it from your data sources.
          </p>
        </>
      ),
    },
    {
      img: two,
      title: 'Transform',
      description: (
        <>
          <p>Grafana Alloy processes data and transforms it for sending.</p>
          <p>Transformations can be used to inject extra metadata into telemetry or filter out unwanted data. </p>
        </>
      ),
    },
    {
      img: three,
      title: 'Write',
      description: (
        <>
          <p>
            Grafana Alloy sends data to OpenTelemetry-compatible databases or collectors, the Grafana LGTM stack, or
            Grafana Cloud.
          </p>
          <p>Grafana Alloy can additionally write alerting rules in compatible databases. </p>
        </>
      ),
    },
  ];

  return (
    <section className={styles.section}>
      <h2>How Grafana Alloy works</h2>
      {theme.isDark ? (
        <img className={styles.diagram} src={alloyDiagramDark} />
      ) : (
        <img className={styles.diagram} src={alloyDiagramLight} />
      )}
      <div className={styles.subSection}>
        {items.map((item, index) => (
          <div key={index}>
            <img src={item.img} />
            <h3>{item.title}</h3>
            {item.description}
          </div>
        ))}
      </div>
    </section>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  section: css({
    backgroundColor: theme.colors.background.secondary,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    textAlign: 'center',
  }),
  diagram: css({
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    marginTop: theme.spacing(4),
  }),
  subSection: css({
    maxWidth: 1200,
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    textAlign: 'left',
    '& img': {
      width: 32,
      height: 32,
    },
    '& h3': {
      marginTop: theme.spacing(2),
    },
    '& p': {
      marginTop: theme.spacing(1),
    },
  }),
});
