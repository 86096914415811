import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { locationService, PluginPage } from '@grafana/runtime';
import { Alert, LoadingPlaceholder } from '@grafana/ui';

import { getPipeline, updatePipeline, useQuery } from '@grafana-cloud/fleet-management-api';
import { useAppDispatch } from 'feature/common/store';
import { alloyIconOrange } from 'img';

import { ConfigurationWizard } from '../components/edit/ConfigurationWizard';
import { onSetData } from '../store/EditConfigurationSlice';

export const UpdateConfiguration: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const dispatch = useAppDispatch();
  const {
    data,
    isPending: isPending,
    error,
    isSuccess,
  } = useQuery(
    getPipeline,
    {
      name,
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(
        onSetData({
          pipeline: {
            name: data.name,
            contents: data.contents,
            matchers: data.matchers,
            enabled: data.enabled || false,
          },
          tested: true,
        })
      );
    }
  }, [isSuccess, data, dispatch]);

  return (
    <PluginPage
      renderTitle={() => <h1>Fleet Management</h1>}
      pageNav={{
        text: 'Edit a Configuration',
        img: alloyIconOrange,
        hideFromBreadcrumbs: false,
      }}
    >
      {isPending && <LoadingPlaceholder text="Loading configuration" />}
      {!!error && (
        <Alert severity="error" title="Error when loading configuration">
          {error.message}
        </Alert>
      )}

      {isSuccess && (
        <ConfigurationWizard
          mutation={updatePipeline}
          title="Edit a configuration"
          onClose={() => {
            locationService.push('../?tab=remote-configuration');
          }}
        />
      )}
    </PluginPage>
  );
};
