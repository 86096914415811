import { QueryVariable } from '@grafana/scenes';

import { getVariablesQuery } from 'feature/common/utils/variables';

const job = new QueryVariable({
  name: 'job',
  datasource: {
    type: 'prometheus',
    uid: 'grafanacloud-prom',
  },
  isMulti: true,
  includeAll: true,
  defaultToAll: true,
  label: 'job',
  query: 'label_values(alloy_build_info, job)',
});

const instance = new QueryVariable({
  name: 'instance',
  datasource: {
    type: 'prometheus',
    uid: 'grafanacloud-prom',
  },
  isMulti: true,
  label: 'instance',
  defaultToAll: true,
  includeAll: true,
  query: 'label_values(alloy_build_info{job=~"$job"}, instance)',
});

const cluster = new QueryVariable({
  name: 'cluster',
  datasource: {
    type: 'prometheus',
    uid: 'grafanacloud-prom',
  },
  isMulti: true,
  label: 'cluster',
  defaultToAll: true,
  includeAll: true,
  query: 'label_values(alloy_build_info, cluster)',
});

const namespace = new QueryVariable({
  name: 'namespace',
  datasource: {
    type: 'prometheus',
    uid: 'grafanacloud-prom',
  },
  isMulti: true,
  label: 'namespace',
  defaultToAll: true,
  includeAll: true,
  query: 'label_values(alloy_build_info, namespace)',
});

const healthVariables = {
  job,
  instance,
  cluster,
  namespace,
} as const;

export type HealthVariableNames = Array<keyof typeof healthVariables>;
export function getHealthVariables(supportedVariables: HealthVariableNames): QueryVariable[] {
  return supportedVariables.map((variable) => healthVariables[variable].clone());
}

export function getHealthVariablesQuery(
  supportedVariables: HealthVariableNames,
  filters: Record<string, string | null>
): string {
  return getVariablesQuery<HealthVariableNames>(supportedVariables, filters);
}
