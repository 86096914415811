import { css } from '@emotion/css';
import React, { useEffect, useRef } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-github_dark';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, useTheme2 } from '@grafana/ui';

import 'ace-builds/src-noconflict/mode-text';
import { useValidationResults } from 'feature/remote-configuration/hooks/useValidationResults';
import { AlloyEditorAnnotations } from 'feature/remote-configuration/types/AlloyEditorAnnotation';

import { AlloyMode } from './AlloyMode';

export const AlloyCodeEditor = ({
  value,
  onChange,
  width = '100%',
  onValidate,
}: {
  value: string;
  width?: string | undefined;
  onChange(value: string): void;
  onValidate: (annotations: AlloyEditorAnnotations[]) => void;
}) => {
  const ace = useRef<AceEditor>(null);
  const styles = useStyles2(getStyles);
  const { isDark } = useTheme2();
  const aceTheme = isDark ? 'github_dark' : 'github';

  useEffect(() => {
    if (ace.current) {
      const mode = new AlloyMode() as any;
      ace.current.editor.getSession().setMode(mode);
    }
  }, []);

  const { annotations, markers } = useValidationResults(value, onValidate);

  return (
    <div className={styles.ct}>
      <AceEditor
        annotations={annotations}
        markers={markers}
        ref={ace}
        theme={aceTheme}
        mode={'text'}
        onChange={onChange}
        width={width}
        value={value}
      />
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    ct: css({
      '.ace_entity.ace_name.ace_type.ace_grafana-alloy': {
        color: theme.isLight ? '#008080' : '#4EC9B0',
      },
      '.ace_entity.ace_name.ace_tag.ace_grafana-alloy': {
        color: '#CB8F77',
      },
      '.alloy-text-error': {
        borderBottom: '2px dotted' + theme.colors.error.text,
        position: 'absolute',
      },
    }),
  };
}
