import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { TabsWrapper } from 'feature/common/components/Tabs/TabsWrapper';
import { TextLink } from 'feature/common/components/TextLink';
import { track } from 'feature/common/utils/track';

export const AlloyOverviewTab: React.FC = () => {
  const styles = useStyles2(getStyles);
  return (
    <TabsWrapper>
      <h2>About Alloy</h2>
      <p>
        <TextLink
          href="https://www.grafana.com/docs/alloy"
          onClick={() =>
            track('documentation_link_clicked', {
              location: 'alloy_overview',
            })
          }
        >
          Grafana Alloy
        </TextLink>{' '}
        is a vendor-neutral distribution of the OpenTelemetry (OTel) Collector. Alloy uniquely combines the very best
        OSS observability signals in the community. It offers native pipelines for OTel, Prometheus, Pyroscope, Loki,
        and many other metrics, logs, traces, and profile tools. In additon, you can also use Alloy pipelines to do
        other tasks such as configure alert rules in Loki and Mimir.
      </p>
      <p>
        Alloy is fully compatible with the OTel Collector, Prometheus Agent, and Promtail. You can use Alloy as an
        alternative to either of these solutions or combined into a hybrid system of multiple collectors and agents.
      </p>
      <div className={styles.subSection}>
        <h4>What can Alloy do?</h4>
        <p>
          Alloy is more than just observability signals like metrics, logs, and traces. It provides many features that
          help you quickly find and process your data in complex environments. Some of these features include:
        </p>
        <ul className={styles.ul}>
          <li>Custom components</li>
          <li>GitOps compatibility</li>
          <li>Clustering support</li>
          <li>Security</li>
          <li>Debugging utilities</li>
        </ul>
      </div>
    </TabsWrapper>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  ul: css({
    dir: 'rtl',
    listStyleType: 'disc',
    padding: 0,
    paddingLeft: '1rem',
  }),
  subSection: css({
    marginTop: theme.spacing(4),
  }),
});
