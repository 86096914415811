import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { CollectorTabs } from 'feature/common/components/Tabs/Tabs';
import { track } from 'feature/common/utils/track';
import { prefixRoute } from 'feature/home/utils/routing';
import { adjustCircle, book, chartLine } from 'img';

import { HomeStepsSectionCard } from './HomeStepsSectionCard';

const cards = [
  {
    icon: adjustCircle,
    title: 'Install an integration',
    text: 'Select and install a Grafana integration in your Grafana Cloud instance.',
    href: 'connections/add-new-connection',
    buttonText: 'Connections',
    onClick: () =>
      track('connections_button_clicked', {
        location: 'landing_page',
      }),
  },
  {
    icon: chartLine,
    title: 'Monitor Alloy',
    text: 'Monitor the key metrics of your Alloy collectors after installation.',
    href: prefixRoute('alloy/' + CollectorTabs.Monitoring),
    buttonText: 'Monitor Alloy',
    onClick: () =>
      track('monitoring_button_clicked', {
        location: 'landing_page',
      }),
  },
  {
    icon: book,
    title: 'Learn about Alloy',
    text: 'Read more about the OpenTelemetry Collector distribution with built-in Prometheus pipelines.',
    href: 'https://www.grafana.com/docs/alloy',
    buttonText: 'Documentation',
    onClick: () =>
      track('documentation_link_clicked', {
        location: 'landing_page',
      }),
  },
];

export const HomeStepsSection: React.FC = () => {
  const styles = useStyles2(getStyles);

  return (
    <section className={styles.section}>
      <div className={styles.cardGroup}>
        {cards.map((card, index) => (
          <HomeStepsSectionCard key={index} {...card} />
        ))}
      </div>
    </section>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  section: css({
    marginBottom: theme.spacing(5),
  }),
  cardGroup: css({
    maxWidth: 1400,
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    '@media screen and (max-width: 1300px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
});
