import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PluginPage } from '@grafana/runtime';
import { LoadingPlaceholder } from '@grafana/ui';

import { AlloyStatus, useTestAlloyConnectionMutation } from '@grafana-cloud/collector';
import { TabsPanel } from '@grafana-cloud/ui';
import { pathToCollectorTab } from 'feature/alloy/utils/tabs';
import { CollectorTabs, TabsType, alloyTabs } from 'feature/common/components/Tabs/Tabs';
import { track } from 'feature/common/utils/track';
import { alloyIconOrange } from 'img';

export const Alloy: React.FC = () => {
  const { pathname } = useLocation();

  const [test, { data, isSuccess, isLoading }] = useTestAlloyConnectionMutation();
  useMemo(() => test(), [test]); // actually fetch the alloy connection information

  const [currentTab, setCurrentTab] = useState<TabsType | undefined>(undefined);

  useEffect(() => {
    if (isLoading) {
      // Don't set the current tab until we have the data
      return;
    }
    // If Alloy is installed, redirect to the Monitoring tab
    const isAlloyInstalled = isSuccess && data === AlloyStatus.Success;

    const selectedTab = isAlloyInstalled ? CollectorTabs.Monitoring : CollectorTabs.Installation;

    // Update the internal state when the URL changes, falling back to the Installation tab if the tab is not found
    // (e.g. when the user clicks their browser back button)
    setCurrentTab(alloyTabs.find((tab) => tab.id === pathToCollectorTab(pathname, selectedTab)));
  }, [data, isSuccess, isLoading, pathname]);

  return (
    <PluginPage
      pageNav={{
        text: 'Grafana Alloy',
        img: alloyIconOrange,
        hideFromBreadcrumbs: true,
        subTitle: 'Manage your Alloy configuration',
      }}
    >
      {isLoading && <LoadingPlaceholder text="Loading..." />}
      <TabsPanel
        tabs={alloyTabs.map((tab) => ({
          id: tab.id,
          label: tab.label,
          component: tab.component,
          active: currentTab?.id === tab.id,
          onChangeTab: () => track('alloy_tab_changed', { tab: tab.id }),
        }))}
      />
    </PluginPage>
  );
};
