import React from 'react';

import { SystemAttributes } from 'feature/common/const/systemAttributes';
import { attributesToSystemAttributes } from 'feature/common/utils/attributes';

import { CellWrapper } from './CellWrapper';
import { CollectorCellProps } from './TableColumns';

export const VersionCell = ({
  row: {
    original: { id, attributes },
  },
}: CollectorCellProps) => {
  const systemAttributes = attributesToSystemAttributes(attributes);
  return (
    <CellWrapper collectorId={id}>
      {systemAttributes.find((systemAttribute) => systemAttribute.id === SystemAttributes.VERSION)?.value}
    </CellWrapper>
  );
};
