export class AlloyHighlightRules extends (window as any).ace.acequire('ace/mode/text_highlight_rules')
  .TextHighlightRules {
  constructor() {
    super();
    this.$rules = {
      start: [
        {
          include: '#expression',
        },
      ],
      '#expression': [
        {
          include: '#literals',
        },
        {
          include: '#comment',
        },
        {
          include: '#string',
        },
        {
          include: '#raw_string',
        },
        {
          include: '#block-header',
        },
      ],
      '#literals': [
        {
          token: 'constant.language.grafana-alloy',
          regex: /\b(?:true|false|null)\b/,
        },
        {
          token: 'constant.numeric.grafana-alloy',
          regex: /\b\d+(?:[Ee][+-]?\d+)?\b/,
        },
        {
          token: 'constant.numeric.grafana-alloy',
          regex: /\b\d+[.]\d*(?:[Ee][+-]?\d+)?\b/,
        },
        {
          token: 'constant.numeric.grafana-alloy',
          regex: /\b[.]\d+(?:[Ee][+-]?\d+)?\b/,
        },
        {
          token: 'meta.block-header.grafana-alloy',
          regex: /}/,
        },
        {
          token: 'keyword.operator',
          regex: '=',
        },
      ],
      '#comment': [
        {
          token: 'comment.line.grafana-alloy',
          regex: /\/\/.*$/,
        },
        {
          token: 'comment.block.grafana-alloy',
          regex: /\/\*/,
          push: [
            {
              token: 'comment.block.grafana-alloy',
              regex: /\*\//,
              next: 'pop',
            },
            {
              defaultToken: 'comment.block.grafana-alloy',
            },
          ],
        },
      ],
      '#string': [
        {
          token: 'string.quoted.double.grafana-alloy',
          regex: /"/,
          push: [
            {
              token: 'string.quoted.double.grafana-alloy',
              regex: /"/,
              next: 'pop',
            },
            {
              token: 'constant.character.escape.grafana-alloy',
              regex: /\\[0-7]{3}/,
            },
            {
              token: 'constant.character.escape.grafana-alloy',
              regex: /\\[abfnrtv'"]/,
            },
            {
              token: 'constant.character.escape.grafana-alloy',
              regex: /\\x[0-9a-fA-F]{2}/,
            },
            {
              token: 'constant.character.escape.grafana-alloy',
              regex: /\\u[0-9a-fA-F]{4}/,
            },
            {
              token: 'constant.character.escape.grafana-alloy',
              regex: /\\U[0-9a-fA-F]{8}/,
            },
            {
              token: 'invalid.illegal.grafana-alloy',
              regex: /\\[^0-7xuUabfnrtv'"]/,
            },
            {
              defaultToken: 'string.quoted.double.grafana-alloy',
            },
          ],
        },
      ],
      '#raw_string': [
        {
          token: 'string.quoted.backtick.grafana-alloy',
          regex: /`/,
          push: [
            {
              token: 'string.quoted.backtick.grafana-alloy',
              regex: /`/,
              next: 'pop',
            },
            {
              defaultToken: 'string.quoted.backtick.grafana-alloy',
            },
          ],
        },
      ],
      '#block-header': [
        {
          token: [
            'meta.block-header.grafana-alloy',
            'entity.name.type.grafana-alloy',
            'meta.block-header.grafana-alloy',
            'entity.name.tag.grafana-alloy',
            'meta.block-header.grafana-alloy',
            'meta.block-header.grafana-alloy',
          ],
          regex: /^(\s*)([A-Za-z_][A-Za-z0-9_]*(?:\.[A-Aa-z_][A-Za-z0-9_]*)*)([^"=]*)((?:".*")?)([^=]*)(\{)/,
        },
      ],
    };

    this.normalizeRules();
  }
}
