import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCollectorIds: [] as string[],
};

export const CollectorListSlice = createSlice({
  name: 'collector-list-slice',
  initialState,
  reducers: {
    toggleSelectedCollector(state, action: PayloadAction<string>) {
      const collectorId = action.payload;
      if (state.selectedCollectorIds.includes(collectorId)) {
        state.selectedCollectorIds = state.selectedCollectorIds.filter((id) => id !== collectorId);
      } else {
        state.selectedCollectorIds.push(collectorId);
      }
    },
    clearSelectedCollectors(state) {
      state.selectedCollectorIds = [];
    },
    selectAllCollectors(state, action: PayloadAction<string[]>) {
      state.selectedCollectorIds = action.payload;
    },
    selectOnlyTheseCollectors(state, action: PayloadAction<string[]>) {
      // adjusts the selected collectors to only include the collectors passed in
      // this is important if the user selects a group of collectors and then filters the collector list.
      // The selected collectors should only be the ones that are currently visible.
      const collectorIds = new Set(action.payload);
      state.selectedCollectorIds = state.selectedCollectorIds.filter((id) => collectorIds.has(id));
    },
  },
});

export const { toggleSelectedCollector, clearSelectedCollectors, selectAllCollectors, selectOnlyTheseCollectors } =
  CollectorListSlice.actions;
