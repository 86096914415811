import React from 'react';

import { getFormattedModifiedDate, getFormattedTimeAgoDate } from 'feature/common/utils/date';

import { CellWrapper } from './CellWrapper';
import { CollectorCellProps } from './TableColumns';

export const LastModifiedCell = ({ row: { original: collector } }: CollectorCellProps) => {
  return (
    <CellWrapper collectorId={collector.id}>
      <span title={getFormattedModifiedDate(collector)}>{getFormattedTimeAgoDate(collector)}</span>{' '}
    </CellWrapper>
  );
};
