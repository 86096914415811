import { css } from '@emotion/css';
import React from 'react';

import { Button, Stack, useStyles2 } from '@grafana/ui';

import { generateAttributeId } from 'feature/common/utils/uuid';

import { AttributeSelectLabels } from './AttributeSelectLabels';
import { AttributeSelectName } from './AttributeSelectName';
import { AttributeSelectValue } from './AttributeSelectValue';

type Props = {
  setAttributesToChange: (attributes: Array<{ id: string; name: string; value: string }>) => void;
  attributesToChange: Array<{ id: string; name: string; value: string }>;
  invalidOptions: Array<{ id: string; name: string; value: string }>;
};

export const AttributeSelectPair = ({ setAttributesToChange, attributesToChange, invalidOptions }: Props) => {
  const styles = useStyles2(getStyles);

  const setAttributeName = (index: number, name: string) => {
    const newAttributes = [...attributesToChange];
    newAttributes[index].name = name;
    setAttributesToChange(newAttributes);
  };

  const setAttributeValue = (index: number, value: string) => {
    const newAttributes = [...attributesToChange];
    newAttributes[index].value = value;
    setAttributesToChange(newAttributes);
  };

  const onRemoveAttribute = (index: number) => {
    if (attributesToChange.length === 1) {
      setAttributesToChange([{ id: generateAttributeId(), name: '', value: '' }]);
      return;
    }
    const newAttributes = [...attributesToChange];
    newAttributes.splice(index, 1);
    setAttributesToChange(newAttributes);
  };

  return (
    <Stack direction="column">
      <AttributeSelectLabels />
      {attributesToChange.map((attribute, index) => (
        <Stack alignItems="flex-end" gap={0} key={attribute.id}>
          <AttributeSelectName
            className={styles.dropdownWidth}
            onChangeAttributeName={(name) => setAttributeName(index, name)}
            value={attribute.name}
            attributesToChange={attributesToChange}
            invalidOptions={invalidOptions}
          />
          <Button className={styles.equalButton} size="md" variant="secondary" disabled>
            =
          </Button>
          <AttributeSelectValue
            className={styles.dropdownWidth}
            attributeName={attribute.name}
            value={attribute.value}
            onChangeAttributeValue={(value) => setAttributeValue(index, value)}
          />
          <Button
            size="md"
            variant="secondary"
            aria-label="Remove attribute"
            icon={'times'}
            onClick={() => onRemoveAttribute(index)}
          />
        </Stack>
      ))}
    </Stack>
  );
};

const getStyles = () => ({
  equalButton: css({
    padding: '0 10px',
  }),
  dropdownWidth: css({
    minWidth: '250px',
    width: 'calc(50% - 32px)', // 1 button = 32px and there are 2 buttons (= and x)
    maxWidth: '300px',
  }),
});
