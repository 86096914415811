import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Collapse, useStyles2 } from '@grafana/ui';

type Props = {
  label: string;
  description: string;
  children: React.ReactNode;
};

export const CollapseWrapper = ({ label, description, children }: Props) => {
  const styles = useStyles2(getStyles);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapse label={label} isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} collapsible={true}>
      <div className={styles.collapseContent}>
        <p>{description}</p>
        {children}
      </div>
    </Collapse>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  collapseContent: css({
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  }),
});
