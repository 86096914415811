import React from 'react';

import { Stack } from '@grafana/ui';

import { AttributeSelectAddButton } from './AttributeSelectAddButton';
import { AttributeSelectPair } from './AttributeSelectPair';

type Props = {
  setAttributesToChange: (attributes: Array<{ id: string; name: string; value: string }>) => void;
  attributesToChange: Array<{ id: string; name: string; value: string }>;
  invalidOptions: Array<{ id: string; name: string; value: string }>;
};

export const AttributeSelect = ({ setAttributesToChange, attributesToChange, invalidOptions }: Props) => {
  return (
    <Stack direction="column">
      <AttributeSelectPair
        setAttributesToChange={setAttributesToChange}
        attributesToChange={attributesToChange}
        invalidOptions={invalidOptions}
      />
      <AttributeSelectAddButton setAttributesToChange={setAttributesToChange} attributesToChange={attributesToChange} />
    </Stack>
  );
};
