import { css } from '@emotion/css';
import React from 'react';

import { Icon, Label, Stack, useStyles2 } from '@grafana/ui';

import { AttributeSelectAddButtonReplace } from './AttributeSelect/AttributeSelectAddButtonReplace';
import { AttributeSelectPair } from './AttributeSelect/AttributeSelectPair';

type Props = {
  attributesToChangeCurrent: Array<{ id: string; name: string; value: string }>;
  attributesToChangeNew: Array<{ id: string; name: string; value: string }>;
  setAttributesToChangeCurrent: (attributes: Array<{ id: string; name: string; value: string }>) => void;
  setAttributesToChangeNew: (attributes: Array<{ id: string; name: string; value: string }>) => void;
  invalidOptions: Array<{ id: string; name: string; value: string }>;
};

export const CollapseReplace = ({
  attributesToChangeCurrent,
  attributesToChangeNew,
  setAttributesToChangeCurrent,
  setAttributesToChangeNew,
  invalidOptions,
}: Props) => {
  const styles = useStyles2(getStyles);
  return (
    <Stack direction="column">
      <Stack gap={0} justifyContent={'space-between'}>
        <Label className={styles.halfWidth}>Current attributes</Label>
        <Label className={styles.halfWidth}>New attributes</Label>
      </Stack>
      <Stack alignItems="end">
        <AttributeSelectPair
          attributesToChange={attributesToChangeCurrent}
          setAttributesToChange={setAttributesToChangeCurrent}
          invalidOptions={invalidOptions}
        />
        <Icon className={styles.icon} name="arrow-right" />
        <AttributeSelectPair
          attributesToChange={attributesToChangeNew}
          setAttributesToChange={setAttributesToChangeNew}
          invalidOptions={invalidOptions}
        />
      </Stack>
      <AttributeSelectAddButtonReplace
        setAttributesToChangeCurrent={setAttributesToChangeCurrent}
        setAttributesToChangeNew={setAttributesToChangeNew}
        attributesToChangeCurrent={attributesToChangeCurrent}
        attributesToChangeNew={attributesToChangeNew}
      />
    </Stack>
  );
};

const getStyles = () => ({
  icon: css({
    margin: '7.5px 20px',
  }),
  halfWidth: css({
    width: '50%',
  }),
});
