import { Pipeline } from '@grafana-cloud/fleet-management-api';
import { getModifiedAtSeconds } from 'feature/common/utils/date';

export enum SortType {
  Alphabetical = 'alphabetical',
  ReverseAlphabetical = 'reverse-alphabetical',
  ModifiedDate = 'modifield-date',
  ReverseModifiedDate = 'reverse-modified-date',
}

export function filterAndSortPipelines(
  pipelines: Pipeline[],
  filter: string,
  sort: SortType = SortType.ModifiedDate
): Pipeline[] {
  return pipelines
    .filter((pipeline) => {
      return (
        pipeline.name?.toLowerCase().includes(filter?.toLocaleLowerCase()) ||
        pipeline.matchers.some((matcher) => matcher.toLowerCase().includes(filter.toLowerCase()))
      );
    })
    .sort((a, b) => {
      switch (sort) {
        case SortType.Alphabetical:
          return a.name?.localeCompare(b.name);
        case SortType.ReverseAlphabetical:
          return b.name?.localeCompare(a.name);
        case SortType.ModifiedDate:
          return getModifiedAtSeconds(b) - getModifiedAtSeconds(a);
        case SortType.ReverseModifiedDate:
          return getModifiedAtSeconds(a) - getModifiedAtSeconds(b);
        default:
          return getModifiedAtSeconds(b) - getModifiedAtSeconds(a);
      }
    });
}
