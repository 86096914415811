interface Attribute {
  name: string;
  value: string;
  operator: AttributeOperator;
  id: string;
}

export type Attributes = Attribute[];

export enum AttributeOperator {
  Equals = '=',
  NotEquals = '!=',
}

export type SystemAttributeTableData = { id: string; name: string; value: string };
