import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { WizardStep } from '../../types/Wizard';

export const WizardHeaderStep = ({ step, index, active }: { step: WizardStep; index: number; active: boolean }) => {
  const styles = useStyles2(getStyles);
  return (
    <span
      className={cx({
        [styles.ct]: true,
        [styles.active]: active,
      })}
    >
      <div className={styles.circle}>{step.done ? <Icon className={styles.check} name="check"></Icon> : index + 1}</div>
      <span>{step.name}</span>
    </span>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    ct: css({
      color: theme.colors.text.secondary,
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    }),
    circle: css({
      borderRadius: '50%',
      display: 'flex',
      lineHeight: theme.spacing(3),
      justifyContent: 'center',
      textAlign: 'center',
      width: 24,
      height: 24,
      fontSize: '12px',
      background: theme.colors.background.secondary,
      color: theme.colors.text.secondary,
      border: `1px solid ${theme.colors.border.strong}`,
    }),
    check: css({
      marginTop: 3,
      color: theme.colors.success.text,
    }),
    active: css({
      color: theme.colors.text.primary,
      '>div': css({
        color: theme.colors.text.primary,
      }),
    }),
  };
}
