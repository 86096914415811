import React from 'react';

import { Alert, ConfirmModal } from '@grafana/ui';

import {
  createPipeline,
  listPipelines,
  queryClient,
  updatePipeline,
  useMutation,
} from '@grafana-cloud/fleet-management-api';
import { useAppSelector } from 'feature/common/store';

type Props = {
  onClose: () => void;
  onSave: () => void;
  mutation: typeof createPipeline | typeof updatePipeline;
};

export const SaveConfigurationModal = ({ onClose, mutation, onSave }: Props) => {
  const { data: configuration } = useAppSelector((state) => state.editConfiguration);
  function onMutationSuccess() {
    queryClient.invalidateQueries({ queryKey: [listPipelines.service.typeName] });
    onSave();
  }

  const { mutate, error, isPending } = useMutation(mutation);
  const activated = configuration.enabled && configuration.matchers.length > 0;

  function save() {
    mutate(
      {
        pipeline: configuration,
      },
      {
        onSuccess: onMutationSuccess,
      }
    );
  }

  const body = (
    <>
      <div>
        {!activated
          ? 'This configuration has been edited but not rolled out to the collectors. Saving this edited configuration will not affect your collectors.'
          : 'This updated configuration will begin rolling out to matching collectors. Do you want to continue?'}
      </div>
      <div>
        {error && (
          <Alert severity="error" title="Error when saving configuration">
            {error.message}
          </Alert>
        )}
      </div>
    </>
  );
  return (
    <ConfirmModal
      isOpen
      title={`Save configuration '${configuration.name}'`}
      body={body}
      confirmText={isPending ? 'Saving...' : 'Save'}
      confirmButtonVariant="primary"
      onConfirm={save}
      dismissVariant="secondary"
      onDismiss={onClose}
    />
  );
};
