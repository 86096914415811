import { useAppDispatch, useAppSelector } from 'feature/common/store';

import {
  editConfigurationSteps,
  onNextStep,
  onPrevStep,
  onResetWizard,
  onSetAnnotations,
  onSetTested,
} from '../store/EditConfigurationSlice';
import { AlloyEditorAnnotations } from '../types/AlloyEditorAnnotation';

export function useEditConfigurationSteps() {
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.editConfiguration);

  return {
    step,
    nextStep: () => dispatch(onNextStep()),
    prevStep: () => dispatch(onPrevStep()),
    resetWizard: () => dispatch(onResetWizard()),
    setTested: () => dispatch(onSetTested()),
    setAnnotations: (annotations: AlloyEditorAnnotations[]) => dispatch(onSetAnnotations(annotations)),
    hasNextStep: () => step < Object.keys(editConfigurationSteps).length - 1,
    hasPrevStep: () => step > 0,
  };
}
