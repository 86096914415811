import { Collector } from '@grafana-cloud/fleet-management-api';

export const filterCollectors = (collectors: Collector[], filter: string): Collector[] => {
  const normalizedFilter = filter.trim().toLocaleLowerCase();

  if (!normalizedFilter) {
    return collectors;
  }

  return collectors.filter((collector: Collector) => {
    const { id, attributeOverrides, name } = collector;
    const normalizedId = id.toLowerCase();
    const normalizedName = name.toLowerCase();
    const attributeStrings = Object.keys(attributeOverrides).map(
      (key) => `${key.toLocaleLowerCase()}=${attributeOverrides[key].toLocaleLowerCase()}`
    );

    return (
      normalizedId.includes(normalizedFilter) ||
      normalizedName.includes(normalizedFilter) ||
      attributeStrings.some((attribute) => attribute.includes(normalizedFilter))
    );
  });
};
