import { css } from '@emotion/css';
import React from 'react';
import SVG from 'react-inlinesvg';

import { GrafanaTheme2 } from '@grafana/data';
import { Card, Icon, LinkButton, useStyles2 } from '@grafana/ui';

type Props = {
  icon: string;
  title: string;
  text: string;
  href: string;
  buttonText: string;
  onClick?: () => void;
};

export const HomeStepsSectionCard = ({ icon, title, text, href, buttonText, onClick }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <Card className={styles.card}>
      <div className={styles.title}>
        <SVG src={icon} />
        <h4>{title}</h4>
      </div>
      <p className={styles.text}>{text}</p>
      <LinkButton fill="text" href={href} onClick={onClick}>
        {buttonText}
        <Icon name="arrow-right" size="lg" />
      </LinkButton>
    </Card>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  card: css({
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textWrap: 'balance',
  }),
  title: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    h4: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0),
    },
  }),
  text: css({
    color: theme.colors.text.secondary,
    marginBottom: theme.spacing(3),
  }),
});
