import { Column } from '@grafana/ui';

import { Collector } from '@grafana-cloud/fleet-management-api';
import { sortStringsAndUndefined } from 'feature/collector-list/utils/sort';
import { SystemAttributes } from 'feature/common/const/systemAttributes';
import { sortLastModified } from 'feature/common/utils/date';

import { AttributesCell } from './AttributesCell';
import { IDCell } from './IDCell';
import { LastModifiedCell } from './LastModifiedCell';
import { NameCell } from './NameCell';
import { OSCell } from './OSCell';
import { SelectCell } from './SelectCell';
import { StatusCell } from './StatusCell';
import { VersionCell } from './VersionCell';

type CollectorCell = { original: Collector };
export type CollectorCellProps = { row: CollectorCell };

export const columns: Array<Column<Collector>> = [
  { id: 'select', header: '', cell: SelectCell, visible: () => true },
  {
    id: 'id',
    sortType: (a: CollectorCell, b: CollectorCell) => {
      const aID = a.original.id;
      const bID = b.original.id;
      return sortStringsAndUndefined(aID, bID);
    },
    header: 'Collector ID',
    cell: IDCell,
    visible: () => true,
  },
  {
    id: 'status',
    // TODO - sort by status
    header: 'Status',
    cell: StatusCell,
    visible: () => true,
  },
  {
    id: 'name',
    sortType: (a: CollectorCell, b: CollectorCell) => {
      const aName = a.original.name;
      const bName = b.original.name;
      return sortStringsAndUndefined(aName, bName);
    },
    header: 'Name',
    cell: NameCell,
    visible: () => true,
  },
  {
    id: SystemAttributes.VERSION,
    sortType: (a: CollectorCell, b: CollectorCell) => {
      const aVersion = a.original.attributeOverrides[SystemAttributes.VERSION];
      const bVersion = b.original.attributeOverrides[SystemAttributes.VERSION];
      return sortStringsAndUndefined(aVersion, bVersion);
    },
    header: 'Version',
    cell: VersionCell,
    visible: () => true,
  },
  {
    id: SystemAttributes.OS,
    sortType: (a: CollectorCell, b: CollectorCell) => {
      const aOS = a.original.attributeOverrides[SystemAttributes.OS];
      const bOS = b.original.attributeOverrides[SystemAttributes.OS];
      return sortStringsAndUndefined(aOS, bOS);
    },
    header: 'OS',
    cell: OSCell,
    visible: () => true,
  },
  { id: 'attributes', header: 'Custom attributes', cell: AttributesCell, visible: () => true },
  {
    id: 'lastModified',
    sortType: (c1: CollectorCell, c2: CollectorCell) => sortLastModified(c1.original, c2.original),
    header: 'Last modified',
    cell: LastModifiedCell,
    visible: () => true,
  },
];
