import React from 'react';

import { Alert, ConfirmModal } from '@grafana/ui';

import { Pipeline, listPipelines, queryClient, updatePipeline, useMutation } from '@grafana-cloud/fleet-management-api';

type Props = {
  onClose: () => void;
  pipeline: Pipeline;
};

export const ToggleActiveModal = ({ onClose, pipeline }: Props) => {
  function onMutationSuccess() {
    queryClient.invalidateQueries({ queryKey: [listPipelines.service.typeName] });
    onClose();
  }

  const { mutate, isPending, error } = useMutation(updatePipeline, {
    onSuccess: onMutationSuccess,
  });

  const active = pipeline.enabled;
  const verb = active ? 'Deactivate' : 'Activate';

  const body = (
    <>
      {!active ? (
        <div>This configuration will begin rolling out to your collectors. Do you want to continue?</div>
      ) : (
        <div>
          This configuration will be deactivated and will stop affecting the matching collectors. <br /> Do you want to
          continue deactivating?
        </div>
      )}
      <div>
        {error && (
          <Alert severity="error" title="Error when updating configuration">
            {error.message}
          </Alert>
        )}
      </div>
    </>
  );
  return (
    <ConfirmModal
      isOpen
      title={`${verb} configuration '${pipeline.name}'`}
      body={body}
      confirmText={isPending ? 'Udating...' : verb}
      onConfirm={() => mutate({ pipeline: { ...pipeline, enabled: !active } })}
      confirmButtonVariant={active ? 'destructive' : 'primary'}
      dismissVariant="secondary"
      onDismiss={onClose}
    />
  );
};
