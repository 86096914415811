import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, Input, useStyles2 } from '@grafana/ui';

type Props = {
  onFilterChange: (filter: string) => void;
};

export const SearchInput = ({ onFilterChange: setFilter }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.searchBar}>
      <Input
        onChange={(ev: React.FormEvent<HTMLInputElement>) => setFilter(ev.currentTarget.value)}
        prefix={<Icon name="search" />}
        placeholder="Search by collector name, ID or matching attributes"
        aria-label="Search collectors"
      />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  searchBar: css({
    display: 'flex',
    flexGrow: 1,
    gap: theme.spacing(2),
    alignItems: 'center',
  }),
});
