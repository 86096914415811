import React from 'react';

import { Icon, useTheme2 } from '@grafana/ui';

import { getState, useQuery } from '@grafana-cloud/fleet-management-api';

import { CellWrapper } from './CellWrapper';
import { CollectorCellProps } from './TableColumns';

export const StatusCell = ({
  row: {
    original: { id: collectorId, name: collectorName },
  },
}: CollectorCellProps) => {
  const { data: state, isLoading: isLoadingState } = useQuery(getState);
  const grafanaTheme = useTheme2();
  const collectorInfo = state?.collectors[collectorId];
  return (
    <CellWrapper collectorId={collectorId}>
      {isLoadingState ? (
        <span>loading...</span>
      ) : (
        <>
          {collectorInfo?.status === 'warning' && (
            <Icon
              style={{
                color: grafanaTheme.colors.warning.text,
              }}
              name="exclamation-circle"
            />
          )}
          {collectorInfo?.status === 'error' && (
            <Icon
              style={{
                color: grafanaTheme.colors.error.text,
              }}
              name="exclamation-circle"
            />
          )}
          {collectorInfo?.status === 'healthy' && (
            <Icon
              style={{
                color: grafanaTheme.colors.success.text,
              }}
              name="check-circle"
            />
          )}
        </>
      )}
    </CellWrapper>
  );
};
