import { css } from '@emotion/css';
import React, { useEffect, useMemo, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Toggletip, useStyles2 } from '@grafana/ui';

import { columns } from 'feature/collector-list/components/CollectorListTable/TableColumns';
import { loadSelectableColumns, persistSelectedColumns } from 'feature/collector-list/utils/localStorage';

import { EditButtonSelection } from './EditButtonSelection';

type Props = {
  setVisibleColumns: (visibleColumns: typeof columns) => void;
  allowSelect: boolean;
};

export const EditButton = ({ setVisibleColumns, allowSelect }: Props) => {
  const styles = useStyles2(getStyles);

  // selectable columns can be shown, hidden and reordered by the user
  const [selectableColumns, setSelectableColumns] = useState(loadSelectableColumns());

  // fixed columns will always be shown
  const fixedColumns = useMemo(
    () => columns.filter((column: { id: string }) => column.id === 'id' || (allowSelect && column.id === 'select')),
    [allowSelect]
  );

  useEffect(() => {
    // Visible columns is generated when the selectable columns changes
    setVisibleColumns(fixedColumns.concat(selectableColumns));
    persistSelectedColumns(selectableColumns);
  }, [fixedColumns, selectableColumns, setVisibleColumns]);

  const columnSelection = (
    <EditButtonSelection setSelectableColumns={setSelectableColumns} selectableColumns={selectableColumns} />
  );

  return (
    <div className={styles.editButton}>
      <Toggletip content={columnSelection} closeButton={false}>
        <Button variant="secondary" fill="outline">
          Edit table
        </Button>
      </Toggletip>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  editButton: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
});
