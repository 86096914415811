import React from 'react';

import { locationService, PluginPage } from '@grafana/runtime';

import { createPipeline } from '@grafana-cloud/fleet-management-api';
import { alloyIconOrange } from 'img';

import { ConfigurationWizard } from '../components/edit/ConfigurationWizard';

export const CreateConfiguration: React.FC = () => {
  return (
    <PluginPage
      renderTitle={() => <h1>Fleet Management</h1>}
      pageNav={{
        text: 'Create Configuration',
        img: alloyIconOrange,
        hideFromBreadcrumbs: false,
      }}
    >
      <ConfigurationWizard
        mutation={createPipeline}
        title="Create a configuration"
        onClose={() => {
          locationService.push('./?tab=remote-configuration');
        }}
      />
    </PluginPage>
  );
};
