import { css } from '@emotion/css';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import { AttributeOperator, Attributes } from 'feature/common/types/Attributes';

import { AttributeItem } from './AttributeItem';

export const AttributesPanel: React.FC<{
  attributes: Attributes;
  onAttributesChange: (attributes: Attributes) => void;
}> = ({ attributes, onAttributesChange }) => {
  function mutateAttributes(mutator: (attributes: Attributes) => void) {
    const newAttributes = [...attributes];
    mutator(newAttributes);
    onAttributesChange(newAttributes);
  }

  function onAddAttribute() {
    mutateAttributes((attributes) =>
      attributes.push({ name: '', value: '', operator: AttributeOperator.Equals, id: uuid() })
    );
  }

  function onAttributeNameChange(index: number, name: string | undefined) {
    mutateAttributes((attributes) => (attributes[index].name = name || ''));
  }

  function onAttributeValueChange(index: number, value: string | undefined) {
    mutateAttributes((attributes) => (attributes[index].value = value || ''));
  }

  function onAttributeOperatorChange(index: number, operator: AttributeOperator) {
    mutateAttributes((attributes) => (attributes[index].operator = operator));
  }

  function onAttributeRemove(index: number) {
    mutateAttributes((attributes) => attributes.splice(index, 1));
  }

  const styles = useStyles2(getStyles);

  return (
    <div className={styles.attributes}>
      {attributes.map((attribute, index) => {
        return (
          <AttributeItem
            attribute={attribute}
            onAttributeNameChange={(attributeName) => onAttributeNameChange(index, attributeName)}
            onAttributeValueChange={(attributeValue) => onAttributeValueChange(index, attributeValue)}
            onAttributeOperatorChange={(operator) => onAttributeOperatorChange(index, operator)}
            onAttributeRemove={() => onAttributeRemove(index)}
            key={attribute.id}
          />
        );
      })}
      <Button icon="plus" onClick={onAddAttribute} variant="secondary">
        {attributes.length === 0 ? 'Filter' : ''}
      </Button>
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    attributes: css({
      display: 'flex',
      rowGap: theme.spacing(1),
      columnGap: theme.spacing(1),
      flexWrap: 'wrap',
    }),
    docsLink: css({
      display: 'block',
    }),
  };
}
