import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { healthOverivewScene } from 'feature/common/scenes/Health/healthOverivewScene';

export const AlloyMonitoringTab: React.FC = () => {
  const styles = useStyles2(getStyles);

  const healthScene = healthOverivewScene(['job', 'instance', 'cluster', 'namespace']);

  return (
    <>
      <section className={styles.monitoringSection}>
        <h2>Monitoring</h2>
        <p>The Grafana Alloy integration lets you monitor metrics of your Alloy instances.</p>
        <br />
        <healthScene.Component model={healthScene} />
      </section>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  monitoringSection: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    margin: '2rem 0',
  }),
});
