import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { AlloyInstallationInstructions } from '@grafana-cloud/collector';
import { TabsWrapper } from 'feature/common/components/Tabs/TabsWrapper';
import { TextLink } from 'feature/common/components/TextLink';
import { track } from 'feature/common/utils/track';

export const AlloyInstallationTab: React.FC = () => {
  const styles = useStyles2(getStyles);

  return (
    <TabsWrapper>
      <div className={styles.conversionSection}>
        <p>
          Are you currently using Grafana Agent? To convert your Grafana Agent to Grafana Alloy, refer to the{' '}
          <TextLink
            href="https://grafana.com/docs/alloy/latest/tasks/migrate/"
            onClick={() =>
              track('migration_documentation_link_clicked', {
                location: 'alloy_installation',
              })
            }
          >
            in-depth instructions in the documentation
          </TextLink>
          .
        </p>
      </div>
      <AlloyInstallationInstructions />
    </TabsWrapper>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  conversionSection: css({
    marginBottom: theme.spacing(4),
  }),
});
