import React from 'react';

import { AttributeBadges } from 'feature/common/components/Attributes/AttributeBadges';

import { CellWrapper } from './CellWrapper';
import { CollectorCellProps } from './TableColumns';

export const AttributesCell = ({
  row: {
    original: { id, attributeOverrides },
  },
}: CollectorCellProps) => {
  // Add a UTF whitespace character to prevent the cell from collapsing when empty.
  // If the cell is empty, the cell will have no height and wont act as clickable.
  return (
    <CellWrapper collectorId={id}>
      <AttributeBadges attributes={(attributeOverrides as { [key: string]: string }) || '\u00A0'} />
    </CellWrapper>
  );
};
