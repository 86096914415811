import React from 'react';

import { CellWrapper } from './CellWrapper';
import { CollectorCellProps } from './TableColumns';

export const IDCell = ({
  row: {
    original: { id: collectorId },
  },
}: CollectorCellProps) => {
  return <CellWrapper collectorId={collectorId}>{collectorId}</CellWrapper>;
};
