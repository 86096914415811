import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Checkbox, Column, Icon, useStyles2 } from '@grafana/ui';

import { Collector } from '@grafana-cloud/fleet-management-api';
import { columns } from 'feature/collector-list/components/CollectorListTable/TableColumns';

type Props = {
  column: Column<Collector>;
  selectableColumns: typeof columns;
  setSelectableColumns: (selectableColumns: typeof columns) => void;
};

export const EditButtonSelectionItem = ({ column, selectableColumns, setSelectableColumns }: Props) => {
  const styles = useStyles2(getStyles);

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
    id: column.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onToggleColumn = (event: React.FormEvent<HTMLInputElement>, columnId: string) => {
    const newColumns = [...selectableColumns];

    const idx = newColumns.findIndex((column) => column.id === columnId);
    if (idx === -1) {
      return;
    }

    const selected = event.currentTarget.checked;

    newColumns[idx] = {
      ...newColumns[idx],
      visible: () => selected,
    };

    setSelectableColumns(newColumns);
  };

  return (
    <div key={column.id} className={styles.tableSelectionItem} ref={setNodeRef} style={style} {...attributes}>
      <Checkbox
        label={column.header}
        name={column.header}
        value={column.visible && column.visible([])}
        onChange={(e) => onToggleColumn(e, column.id)}
      />
      <div ref={setActivatorNodeRef} {...listeners}>
        <Icon name="draggabledots" />
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  tableSelectionItem: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  }),
});
