import React, { useMemo, useState } from 'react';

import { SelectableValue } from '@grafana/data';
import { Select, Stack } from '@grafana/ui';

import { listCollectorAttributes, useQuery } from '@grafana-cloud/fleet-management-api';

type Props = {
  onChangeAttributeName: (attributeName: string) => void;
  value: string;
  attributesToChange: Array<{ id: string; name: string; value: string }>;
  invalidOptions: Array<{ id: string; name: string; value: string }>;
} & React.HTMLAttributes<HTMLDivElement>;

export const AttributeSelectName = ({
  onChangeAttributeName,
  value,
  attributesToChange,
  invalidOptions,
  ...divProps
}: Props) => {
  const [customOptions, setCustomOptions] = useState<SelectableValue[]>([]);

  const { data, isLoading } = useQuery(listCollectorAttributes);

  const options = useMemo(() => {
    const availableAttributes = data?.attributes || {};
    const availableAttributeNames = Object.keys(availableAttributes).filter(
      (name) => !attributesToChange.some((attribute) => attribute.name === name)
    );
    // remove the invalid options from the available options
    availableAttributeNames.forEach((name) => {
      invalidOptions.forEach((option) => {
        if (name === option.name) {
          availableAttributeNames.splice(availableAttributeNames.indexOf(name), 1);
        }
      });
    });
    return availableAttributeNames.map((name) => ({ label: name, value: name }));
  }, [attributesToChange, data?.attributes, invalidOptions]);

  const updateValue = (v: SelectableValue<string>) => {
    onChangeAttributeName(v.value ?? '');
  };

  return (
    <Stack direction="column" {...divProps}>
      <Select
        options={[...options, ...customOptions]}
        isLoading={isLoading}
        value={value}
        onChange={(v) => {
          updateValue(v);
        }}
        allowCustomValue={true}
        onCreateOption={(v: string) => {
          const customValue = { label: v, value: v };
          setCustomOptions((_customOptions) => [..._customOptions, customValue]);
          updateValue(customValue);
        }}
        isValidNewOption={(v) =>
          !(
            attributesToChange.some((attribute) => attribute.name === v) ||
            invalidOptions.some((option) => option.name === v)
          )
        }
        placeholder="Select or type attribute name"
      />
    </Stack>
  );
};
