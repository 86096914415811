import { css } from '@emotion/css';
import React, { useEffect, useMemo } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Checkbox, InteractiveTable, useStyles2 } from '@grafana/ui';

import { Collector } from '@grafana-cloud/fleet-management-api';
import {
  clearSelectedCollectors,
  selectAllCollectors,
  selectOnlyTheseCollectors,
} from 'feature/collector-list/store/CollectorListSlice';
import { useAppDispatch, useAppSelector } from 'feature/common/store';

import { columns } from './TableColumns';

type Props = {
  visibleColumns: typeof columns;
  filteredCollectors: Collector[];
  allowSelect: boolean;
};

export const CollectorListTable = ({ visibleColumns, filteredCollectors, allowSelect }: Props) => {
  const styles = useStyles2(getStyles);
  const dispatch = useAppDispatch();
  const selectedCollectors = useAppSelector((state) => state.collectorList.selectedCollectorIds);

  const isAllSelected = useMemo(
    () => filteredCollectors.length > 0 && filteredCollectors.every((c) => selectedCollectors.includes(c.id)),
    [filteredCollectors, selectedCollectors]
  );

  const onSelectAll = () => {
    if (isAllSelected) {
      dispatch(clearSelectedCollectors());
    } else {
      dispatch(selectAllCollectors(filteredCollectors.map((c) => c.id)));
    }
  };

  useEffect(() => {
    // make sure that the selected collectors are only the ones that are currently visible
    dispatch(selectOnlyTheseCollectors(filteredCollectors.map((c) => c.id)));
  }, [dispatch, filteredCollectors]);

  return (
    <div className={styles.wrapper}>
      <InteractiveTable
        columns={visibleColumns}
        data={filteredCollectors}
        getRowId={(c: Collector) => c.id}
        className={styles.noPadding}
      />
      {allowSelect && (
        <Checkbox
          className={styles.selectAllCheckbox}
          value={isAllSelected}
          indeterminate={selectedCollectors.length > 0 && !isAllSelected}
          onChange={() => onSelectAll()}
        />
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css({
      position: 'relative',
    }),
    selectAllCheckbox: css({
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
    }),
    noPadding: css({
      td: {
        padding: 0,
      },
    }),
  };
};
