import { CollectorTabs } from 'feature/common/components/Tabs/Tabs';

export const pathToCollectorTab = (pathname: string, defaultTab: CollectorTabs): CollectorTabs => {
  const path = pathname.split('/').pop();
  switch (path) {
    case 'installation':
      return CollectorTabs.Installation;
    case 'overview':
      return CollectorTabs.Overview;
    case 'monitoring':
      return CollectorTabs.Monitoring;
  }
  return defaultTab;
};
