export const sortStringsAndUndefined = (a: string | undefined, b: string | undefined) => {
  if (a === b) {
    return 0;
  }
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  return a.localeCompare(b);
};
