import React, { useEffect, useMemo, useState } from 'react';

import { Stack } from '@grafana/ui';

import { listCollectors, useQuery } from '@grafana-cloud/fleet-management-api';
import { filterCollectors } from 'feature/collector-list/utils/collectors';
import { sortLastModified } from 'feature/common/utils/date';

import { CollectorListBar } from './CollectorListBar/CollectorListBar';
import { CollectorListTable } from './CollectorListTable/CollectorListTable';
import { columns } from './CollectorListTable/TableColumns';

type Props = {
  allowSearch?: boolean;
  matchers?: string[];
  allowSelect?: boolean;
  allowFilter?: boolean;
};

export const CollectorList: React.FC<Props> = ({
  allowSearch = false,
  allowSelect = false,
  allowFilter = false,
  matchers: externalMatchers,
}: Props) => {
  const [matchers, setMatchers] = useState<string[]>(externalMatchers ?? []);

  useEffect(() => {
    setMatchers(externalMatchers ?? []);
  }, [externalMatchers]);

  const { data: collectors } = useQuery(listCollectors, { matchers });

  const [visibleColumns, setVisibleColumns] = useState(
    allowSelect ? columns : columns.filter((column) => column.id !== 'select')
  );
  const [filter, setFilter] = useState<string>('');

  const filteredCollectors = useMemo(() => {
    // Sort collectors by last modified date by default
    // TODO: replace this with the official InteractiveTable initial sorting when released
    // https://github.com/grafana/grafana/pull/89937
    const _collectors = collectors?.collectors.sort((a, b) => sortLastModified(b, a)) ?? [];
    return filterCollectors(_collectors ?? [], filter);
  }, [collectors, filter]);

  return (
    <Stack direction="column">
      <CollectorListBar
        setFilter={setFilter}
        setVisibleColumns={setVisibleColumns}
        onMatchersChange={setMatchers}
        allowSearch={allowSearch}
        allowSelect={allowSelect}
        allowFilter={allowFilter}
        filteredCollectors={filteredCollectors}
      />
      <CollectorListTable
        visibleColumns={visibleColumns}
        filteredCollectors={filteredCollectors}
        allowSelect={allowSelect}
      />
    </Stack>
  );
};
