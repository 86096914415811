import {
  EmbeddedScene,
  PanelBuilders,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';

import { getLogsVariables, getLogsVariablesQuery, LogsVariableNames } from './logsQueryVariables';

export const collectorLogsScene = (
  allowedVariables: LogsVariableNames,
  filters: Record<string, string | null> = {}
) => {
  const variablesQuery = getLogsVariablesQuery(allowedVariables, filters);

  const logsQueryRunner = new SceneQueryRunner({
    datasource: {
      type: 'loki',
      uid: 'grafanacloud-logs',
    },
    queries: [
      {
        refId: 'A',
        intervalMs: 1000,
        legendFormat: '',
        maxDataPoints: 1000,
        maxLines: 1000,
        queryType: 'range',
        expr: `{${variablesQuery}}`,
      },
    ],
  });

  const logsPanel = PanelBuilders.logs().setTitle('').setData(logsQueryRunner);

  return new EmbeddedScene({
    $variables: new SceneVariableSet({
      variables: getLogsVariables(allowedVariables),
    }),
    $timeRange: new SceneTimeRange({ from: 'now-4h', to: 'now' }),
    controls: [
      new SceneFlexLayout({
        children: [
          new SceneRefreshPicker({ isOnCanvas: true }),
          new SceneTimePicker({ isOnCanvas: true }),
          new VariableValueSelectors({}),
        ],
      }),
    ],
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        new SceneFlexItem({
          width: '100%',
          minHeight: 200,
          body: logsPanel.build(),
        }),
      ],
    }),
  });
};
