import { css } from '@emotion/css';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { Column, InteractiveTable, Stack, useStyles2 } from '@grafana/ui';

import { getCollector, useQuery } from '@grafana-cloud/fleet-management-api';
import { collectorIdSearchParam } from 'feature/collector-list/const/searchParams';
import { AttributeBadges } from 'feature/common/components/Attributes/AttributeBadges';
import { SystemAttributeTableData } from 'feature/common/types/Attributes';
import { attributesToSystemAttributes } from 'feature/common/utils/attributes';

export const CollectorDetailAttributes = () => {
  const styles = useStyles2(getStyles);

  const [params] = useSearchParams();
  const collectorId = params.get(collectorIdSearchParam);
  const { data: collector } = useQuery(getCollector, { id: collectorId ?? '' }, { enabled: !!collectorId });

  const systemAttributes = attributesToSystemAttributes(collector?.attributes ?? {});

  const columns: Array<
    Column<{
      [key: string]: string;
    }>
  > = [
    { id: 'name', header: 'Property' },
    { id: 'value', header: 'Value' },
  ];

  return collector ? (
    <Stack direction="column" gap={4}>
      <Stack>
        <span className={styles.attributeText}>Custom attributes</span>
        <AttributeBadges attributes={collector.attributeOverrides} />
      </Stack>
      <InteractiveTable columns={columns} data={systemAttributes} getRowId={(c: SystemAttributeTableData) => c.id} />
    </Stack>
  ) : (
    <>Loading...</>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  attributeText: css({
    color: theme.colors.text.maxContrast,
    marginRight: theme.spacing(3),
  }),
});
