import { css } from '@emotion/css';
import React from 'react';
import SVG from 'react-inlinesvg';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { alloyIconOrange, openTelemetryLogo, prometheusLogo } from 'img';

export const HomeTitleSection: React.FC = () => {
  const styles = useStyles2(getStyles);

  return (
    <section className={styles.section}>
      <SVG className={styles.logo} src={alloyIconOrange} />
      <h1>Grafana Alloy</h1>
      <p className={styles.secondaryText}>A flexible, high performance, vendor-neutral telemetry collector</p>
      <p>compatible with:</p>
      <div className={styles.logoGroup}>
        <SVG height={58} src={openTelemetryLogo} />
        <SVG height={58} src={prometheusLogo} />
      </div>
    </section>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  section: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
  }),
  logo: css({
    width: 70,
    height: 70,
    marginBottom: '1rem',
  }),
  secondaryText: css({
    color: theme.colors.text.secondary,
  }),
  logoGroup: css({
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
  }),
});
