import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux';

import { CollectorListSlice } from 'feature/collector-list/store/CollectorListSlice';
import { EditConfigurationSlice } from 'feature/remote-configuration/store/EditConfigurationSlice';

import { AppApiContext } from './AppApiContext';

export const store = configureStore({
  reducer: {
    editConfiguration: EditConfigurationSlice.reducer,
    collectorList: CollectorListSlice.reducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = createDispatchHook<AppState>(AppApiContext);
export const useAppSelector: TypedUseSelectorHook<AppState> = createSelectorHook(AppApiContext);
