import React from 'react';

import { healthOverivewScene } from '../../common/scenes/Health/healthOverivewScene';
import { useCollectorId } from '../hooks/useCollectorId';

export const CollectorDetailHealth = () => {
  const collector_id = useCollectorId();
  const healthScene = healthOverivewScene([], {
    collector_id,
  });
  return (
    <div>
      <h1>Collector Health</h1>
      <healthScene.Component model={healthScene} />
    </div>
  );
};
