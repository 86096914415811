import React, { useEffect } from 'react';

import {
  CollectorInstallationInstructions,
  InstallMode,
  setInstallationModeValue,
  useCollectorDispatch,
} from '@grafana-cloud/collector';
import { AppProviders } from 'feature/app/components/AppProviders';

export interface CollectorInstallationInstructionsExtensionProps {
  showTestConnection?: boolean;
  showOSSelection?: boolean;
  showTitle?: boolean;
  installMode?: InstallMode;
}

export function CollectorInstallationInstructionsExtension({
  showTestConnection = true,
  showOSSelection = true,
  showTitle = true,
  installMode = InstallMode.ALLOY,
}) {
  return (
    <AppProviders>
      <CollectorInstallationInstructionsWrapper installMode={installMode}>
        <CollectorInstallationInstructions
          showOSSelection={showOSSelection}
          showTestConnection={showTestConnection}
          showTitle={showTitle}
        />
      </CollectorInstallationInstructionsWrapper>
    </AppProviders>
  );
}

function CollectorInstallationInstructionsWrapper({
  children,
  installMode,
}: {
  children: React.JSX.Element;
  installMode: InstallMode;
}) {
  const collectorDispatch = useCollectorDispatch();

  useEffect(() => {
    collectorDispatch(setInstallationModeValue({ installationModeValue: installMode }));
  }, [collectorDispatch, installMode]);

  return children;
}
