import React from 'react';

import { Select } from '@grafana/ui';

import { listCollectorAttributes, useQuery } from '@grafana-cloud/fleet-management-api';

export const AttributeNameSelect: React.FC<{
  attributeName: string;
  onChange: (attributeName: string | undefined) => void;
}> = ({ attributeName, onChange }) => {
  const { data, isLoading } = useQuery(listCollectorAttributes);
  const availableAttributes = data?.attributes || {};
  const availableAttributeNames = Object.keys(availableAttributes);
  return (
    <Select
      onChange={(value) => onChange(value.value)}
      isLoading={isLoading}
      placeholder="Select Attribute"
      value={attributeName}
      options={availableAttributeNames.map((name) => ({ label: name, value: name }))}
    />
  );
};
