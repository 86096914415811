import { columns } from 'feature/collector-list/components/CollectorListTable/TableColumns';
import { localStorageKey } from 'feature/collector-list/const/localStorageKey';
import { SimplifiedColumn } from 'feature/collector-list/types/Columns';

export const loadSelectableColumns = (): typeof columns => {
  const storedColumns = localStorage.getItem(localStorageKey);
  if (storedColumns) {
    try {
      const _columns = JSON.parse(storedColumns) as SimplifiedColumn[];
      const _selectedColumns: typeof columns = [];
      for (const c of _columns) {
        // Find the column in the original columns array
        const column = columns.find((col) => col.id === c.id);
        if (column) {
          _selectedColumns.push({
            ...column,
            visible: () => c.visible, // restore visibility value from stored data
          });
        }
      }
      return _selectedColumns;
    } catch (e) {
      console.error('Error parsing stored columns', e);
      localStorage.removeItem(localStorageKey); // remove invalid data
    }
  }
  return columns.filter((column) => column.id !== 'select' && column.id !== 'id');
};

export const persistSelectedColumns = (selectedColumns: typeof columns) => {
  // Store a simplified representation of the selected columns in the local state
  const _columnsToStore: SimplifiedColumn[] = selectedColumns.map((column) => ({
    id: column.id as string,
    visible: !!(column.visible && column.visible([])),
  }));
  localStorage.setItem(localStorageKey, JSON.stringify(_columnsToStore));
};
