import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Alert, Drawer, LoadingPlaceholder } from '@grafana/ui';

import { getCollector, useQuery } from '@grafana-cloud/fleet-management-api';
import { TabsPanel } from '@grafana-cloud/ui';
import { collectorIdSearchParam, collectorTabSearchParam } from 'feature/collector-list/const/searchParams';
import { collectorDetailTabs } from 'feature/common/components/Tabs/Tabs';
import { track } from 'feature/common/utils/track';

export const CollectorDetail: React.FC = () => {
  const [params, setParams] = useSearchParams();
  const collectorId = params.get(collectorIdSearchParam);

  const {
    data: collector,
    error,
    isPending,
    isSuccess,
  } = useQuery(getCollector, { id: collectorId ?? '' }, { enabled: !!collectorId });

  const onClose = () => {
    setParams((p) => {
      p.delete(collectorIdSearchParam);
      p.delete(collectorTabSearchParam);
      return p;
    });
  };
  return (
    <>
      <Drawer title={collector?.name} size="md" onClose={onClose}>
        {!!error && (
          <Alert severity="error" title="Error when loading collector">
            {error.message}
          </Alert>
        )}
        {isPending && <LoadingPlaceholder text="Loading..." />}
        {isSuccess && collector && (
          <TabsPanel
            tabs={collectorDetailTabs.map((tab) => ({
              id: tab.id,
              label: tab.label,
              component: tab.component,
              active: tab.isDefault,
              onChangeTab: () => track('collector_detail_tab_changed', { tab: tab.id }),
            }))}
            paramName={collectorTabSearchParam}
          />
        )}
      </Drawer>
    </>
  );
};
