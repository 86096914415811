import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Badge, useStyles2 } from '@grafana/ui';

import { PipelineCellProps } from './ConfigurationList';

export const MatchingAttributesCell = ({
  row: {
    original: { matchers },
  },
}: PipelineCellProps) => {
  const styles = useStyles2(getStyles);
  if (matchers.length === 0) {
    return <i>The configuration process is incomplete.</i>;
  } else {
    return matchers.map((matcher: string) => (
      <Badge text={matcher} className={styles.matcher} key={matcher} color={'blue'} />
    ));
  }
};

function getStyles(theme: GrafanaTheme2) {
  return {
    matcher: css({
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.25),
      marginTop: theme.spacing(0.25),
    }),
  };
}
