import { Timestamp } from '@bufbuild/protobuf';

import { dateTimeFormatTimeAgo } from '@grafana/data';

type DateItem = {
  createdAt?: Timestamp | undefined;
  updatedAt?: Timestamp | undefined;
};

export function getFormattedModifiedDate<T extends DateItem>(item: T): string {
  return getModifiedDate(item)?.toLocaleString() ?? 'N/A';
}

export function getFormattedTimeAgoDate<T extends DateItem>(item: T): string {
  const modifiedDate = getModifiedDate(item);
  return modifiedDate ? dateTimeFormatTimeAgo(modifiedDate) : 'N/A';
}

export function getModifiedAtSeconds<T extends DateItem>(item: T): number {
  return Number(item?.updatedAt?.seconds || item?.createdAt?.seconds || 0);
}

function getModifiedDate<T extends DateItem>(item: T): Date | undefined {
  return (item.updatedAt || item.createdAt)?.toDate();
}

export function sortLastModified<T extends DateItem>(item1: T, item2: T): number {
  return getModifiedAtSeconds(item1) - getModifiedAtSeconds(item2);
}
