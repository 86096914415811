import React from 'react';

import { Alert, ConfirmModal } from '@grafana/ui';

import { Pipeline, deletePipeline, listPipelines, queryClient, useMutation } from '@grafana-cloud/fleet-management-api';

type Props = {
  onClose: () => void;
  pipeline: Pipeline;
};

export const DeleteConfigurationModal = ({ onClose, pipeline }: Props) => {
  function onMutationSuccess() {
    queryClient.invalidateQueries({ queryKey: [listPipelines.service.typeName] });
    onClose();
  }

  const {
    mutate: doDelete,
    isPending: isDeletePending,
    error,
  } = useMutation(deletePipeline, {
    onSuccess: onMutationSuccess,
  });

  const body = (
    <>
      <div>
        Deleting this configuration is permanent and cannot be undone. Existing collectors will remove this
        configuration. Do you want to continue deleting?
      </div>
      <div>
        {error && (
          <Alert severity="error" title="Error when deleting configuration">
            {error.message}
          </Alert>
        )}
      </div>
    </>
  );
  return (
    <ConfirmModal
      isOpen
      title={`Delete configuration '${pipeline.name}'`}
      body={body}
      confirmText={isDeletePending ? 'Deleting...' : 'Delete'}
      onConfirm={() => doDelete(pipeline)}
      onDismiss={onClose}
      confirmButtonVariant="destructive"
      dismissVariant="secondary"
    />
  );
};
