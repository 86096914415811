import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

interface Props {
  children: React.ReactNode;
}

export const TabsWrapper: React.FC<Props> = ({ children }) => {
  const styles = useStyles2(getStyles);

  return <div className={styles.container}>{children}</div>;
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    margin: '2rem 0',
    maxWidth: 700,
  }),
});
