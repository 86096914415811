import React from 'react';

import { collectorLogsScene } from '../../common/scenes/Logs/collectorLogsScene';
import { useCollectorId } from '../hooks/useCollectorId';

export const CollectorDetailLogs = () => {
  const collector_id = useCollectorId();
  const logsScene = collectorLogsScene([], {
    collector_id,
  });
  return (
    <div>
      <h1>Collector Logs</h1>
      <logsScene.Component model={logsScene} />
    </div>
  );
};
