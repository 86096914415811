import React from 'react';

import { Button, Stack } from '@grafana/ui';

import { generateAttributeId } from 'feature/common/utils/uuid';

type Props = {
  setAttributesToChange: (attributes: Array<{ id: string; name: string; value: string }>) => void;
  attributesToChange: Array<{ id: string; name: string; value: string }>;
};

export const AttributeSelectAddButton = ({ setAttributesToChange, attributesToChange }: Props) => {
  const onAddAttribute = () => {
    setAttributesToChange([...attributesToChange, { id: generateAttributeId(), name: '', value: '' }]);
  };
  const addDisabled = attributesToChange.some((attribute) => attribute.name === '' || attribute.value === '');

  return (
    <Stack>
      <Button
        icon="plus"
        onClick={onAddAttribute}
        variant="secondary"
        disabled={addDisabled}
        tooltip={addDisabled ? 'Ensure all key value pairs are not empty' : undefined}
      />
    </Stack>
  );
};
