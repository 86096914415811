import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { CollectorDetail } from 'feature/collector-detail/components/CollectorDetail';
import { CollectorList } from 'feature/collector-list/components/CollectorList';
import { collectorIdSearchParam } from 'feature/collector-list/const/searchParams';

export const FleetInventory: React.FC = () => {
  const [params] = useSearchParams();
  const collectorId = params.get(collectorIdSearchParam);

  return (
    <>
      {collectorId && <CollectorDetail />}
      <CollectorList allowSelect={true} allowSearch={true} allowFilter={true} />
    </>
  );
};
