import { QueryVariable } from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';

import { getVariablesQuery } from 'feature/common/utils/variables';

const collector_id = new QueryVariable({
  name: 'collector_id',
  datasource: {
    type: 'loki',
    uid: 'grafanacloud-logs',
  },
  isMulti: false,
  label: 'collector_id',
  defaultToAll: false,
  includeAll: false,
  hide: VariableHide.hideVariable,
});

const logsVariables = {
  collector_id,
} as const;

export type LogsVariableNames = Array<keyof typeof logsVariables>;
export function getLogsVariables(supportedVariables: LogsVariableNames): QueryVariable[] {
  return supportedVariables.map((variable) => logsVariables[variable].clone());
}

export function getLogsVariablesQuery(
  supportedVariables: LogsVariableNames,
  filters: Record<string, string | null>
): string {
  return getVariablesQuery<LogsVariableNames>(supportedVariables, filters);
}
