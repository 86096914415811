import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Checkbox, useStyles2 } from '@grafana/ui';

import { toggleSelectedCollector } from 'feature/collector-list/store/CollectorListSlice';
import { useAppDispatch, useAppSelector } from 'feature/common/store';

import { CollectorCellProps } from './TableColumns';

export const SelectCell = ({
  row: {
    original: { id: collectorId },
  },
}: CollectorCellProps) => {
  const styles = useStyles2(getStyles);
  const dispatch = useAppDispatch();
  const selectedCollectors = useAppSelector((state) => state.collectorList.selectedCollectorIds);

  return (
    <Checkbox
      className={styles.checkbox}
      value={selectedCollectors.includes(collectorId)}
      onChange={() => dispatch(toggleSelectedCollector(collectorId))}
    />
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  checkbox: css({
    padding: theme.spacing(1),
  }),
});
