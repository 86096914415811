import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Modal, useStyles2, Stack, Button } from '@grafana/ui';

// import { listCollectorAttributes, listCollectors, useQuery } from '@grafana-cloud/fleet-management-api';
// import { useAppSelector } from 'feature/common/store';
// import { Attributes } from 'feature/common/types/Attributes';

import { generateAttributeId } from '../../../common/utils/uuid';

import { AttributeSelect } from './Collapse/AttributeSelect/AttributeSelect';
import { CollapseReplace } from './Collapse/CollapseReplace';
import { CollapseWrapper } from './Collapse/CollapseWrapper';

// type CollectorAttribute = {
//   [key: string]: string;
// };

// type AttributeReplacementPair = [CollectorAttribute, CollectorAttribute];

type Props = {
  setShowModal: (showModal: boolean) => void;
};

export const AttributeEditModal = ({ setShowModal }: Props) => {
  const styles = useStyles2(getStyles);
  //   const selectedCollectorIds = useAppSelector((state) => state.collectorList.selectedCollectorIds);

  //   const { data: existing, isLoading } = useQuery(listCollectorAttributes);

  //   const { data: allCollectors } = useQuery(listCollectors);
  //   const selectedCollectors = useMemo(() => {
  //     return allCollectors?.collectors.filter((collector) => selectedCollectorIds.includes(collector.id)) ?? [];
  //   }, [allCollectors, selectedCollectorIds]);

  //   const attributesOfSelectedCollectors: Attributes = useMemo(() => {
  //     return [];
  //   }, []);

  // Changes to be made to the selected collectors
  const [attributesToAdd, setAttributesToAdd] = useState<Array<{ id: string; name: string; value: string }>>([
    {
      id: generateAttributeId(),
      name: '',
      value: '',
    },
  ]);
  const [attributesToRemove, setAttributesToRemove] = useState<Array<{ id: string; name: string; value: string }>>([
    {
      id: generateAttributeId(),
      name: '',
      value: '',
    },
  ]);
  const [attributesToReplaceCurrent, setAttributesToReplaceCurrent] = useState<
    Array<{ id: string; name: string; value: string }>
  >([
    {
      id: generateAttributeId(),
      name: '',
      value: '',
    },
  ]);
  const [attributesToReplaceNew, setAttributesToReplaceNew] = useState<
    Array<{ id: string; name: string; value: string }>
  >([
    {
      id: generateAttributeId(),
      name: '',
      value: '',
    },
  ]);

  const invalidOptionsForReplace = [...attributesToAdd, ...attributesToRemove];
  const invalidOptionsForAdd = [...attributesToRemove, ...attributesToReplaceCurrent, ...attributesToReplaceNew];
  const invalidOptionsForRemove = [...attributesToAdd, ...attributesToReplaceCurrent, ...attributesToReplaceNew];

  const collapseItems = [
    {
      id: 'add',
      label: 'Add custom attributes',
      description:
        'This action enables you to create custom attributes that will be applied to all selected collectors.',
      component: (
        <AttributeSelect
          attributesToChange={attributesToAdd}
          setAttributesToChange={setAttributesToAdd}
          invalidOptions={invalidOptionsForAdd}
        />
      ),
    },
    {
      id: 'remove',
      label: 'Remove custom attributes',
      description: 'This action enables you to remove custom attributes from all selected collectors.',
      component: (
        <AttributeSelect
          attributesToChange={attributesToRemove}
          setAttributesToChange={setAttributesToRemove}
          invalidOptions={invalidOptionsForRemove}
        />
      ),
    },
    {
      id: 'replace',
      label: 'Replace custom attributes',
      description:
        'This action allows you to replace existing custom attributes with new ones. The updates will be applied to all selected collectors.',
      component: (
        <CollapseReplace
          attributesToChangeCurrent={attributesToReplaceCurrent}
          attributesToChangeNew={attributesToReplaceNew}
          setAttributesToChangeCurrent={setAttributesToReplaceCurrent}
          setAttributesToChangeNew={setAttributesToReplaceNew}
          invalidOptions={invalidOptionsForReplace}
        />
      ),
    },
  ];

  return (
    <Modal className={styles.modal} title="Bulk edit custom attributes" isOpen onDismiss={() => setShowModal(false)}>
      <p>
        Here you can modify the attributes applied to your collectors. You can add new attributes, remove attributes,
        and modify existing attributes to replace them.
      </p>

      {collapseItems.map((item) => (
        <>
          <CollapseWrapper label={item.label} description={item.description}>
            {item.component || null}
          </CollapseWrapper>
        </>
      ))}
      <Stack justifyContent={'flex-end'}>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button variant="secondary">Apply changes</Button>
      </Stack>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  modal: css({
    width: '1400px',
  }),
});
