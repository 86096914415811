import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import { AttributeOperator } from 'feature/common/types/Attributes';

import { AttributeNameSelect } from './AttributeNameSelect';
import { AttributeOperatorSelect } from './AttributeOperatorSelect';
import { AttributeValueSelect } from './AttributeValueSelect';
interface Attribute {
  name: string;
  value: string;
  operator: AttributeOperator;
}

export const AttributeItem: React.FC<{
  attribute: Attribute;
  onAttributeNameChange: (name: string | undefined) => void;
  onAttributeValueChange: (value: string | undefined) => void;
  onAttributeOperatorChange: (operator: AttributeOperator) => void;
  onAttributeRemove: () => void;
}> = ({ attribute, onAttributeNameChange, onAttributeValueChange, onAttributeOperatorChange, onAttributeRemove }) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.ct}>
      <AttributeNameSelect attributeName={attribute.name} onChange={onAttributeNameChange} />

      <AttributeOperatorSelect attributeOperator={attribute.operator} onChange={onAttributeOperatorChange} />

      <AttributeValueSelect
        attributeName={attribute.name}
        attributeValue={attribute.value}
        key={attribute.name + '_' + attribute.value}
        onChange={onAttributeValueChange}
      />
      <Button size="md" variant="secondary" aria-label="Remove attribute" icon={'times'} onClick={onAttributeRemove} />
    </div>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    ct: css({
      '>*': {
        flexBasis: 'content',
      },
      // this fixes an issue with select boxes shrinking when the menu is open
      svg: {
        width: '16px',
        height: '16px',
      },
      display: 'inline-flex',
    }),
  };
}
