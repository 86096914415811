import React from 'react';

import { Select } from '@grafana/ui';

import { listCollectorAttributes, useQuery } from '@grafana-cloud/fleet-management-api';

export const AttributeValueSelect: React.FC<{
  attributeValue: string;
  attributeName: string;
  onChange: (attributeValue: string | undefined) => void;
}> = ({ attributeValue, attributeName, onChange }) => {
  const { data, isLoading } = useQuery(listCollectorAttributes);
  const availableAttributes = data?.attributes || {};
  const selectedAttribute = availableAttributes[attributeName];
  const options = selectedAttribute?.values.map((value) => ({ label: value.value, value: `"${value.value}"` })) || [];
  return (
    <Select
      onChange={(value) => onChange(value.value)}
      placeholder="Select Value"
      value={attributeValue}
      isLoading={isLoading}
      noOptionsMessage="Please select an attribute first."
      options={options}
    />
  );
};
