import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Column, InteractiveTable, Switch, useStyles2 } from '@grafana/ui';

import { Pipeline } from '@grafana-cloud/fleet-management-api';
import { getFormattedModifiedDate, getFormattedTimeAgoDate, sortLastModified } from 'feature/common/utils/date';

import { MatchingAttributesCell } from './MatchingAttributesCell';

type PipelineCell = { original: Pipeline };
export type PipelineCellProps = { row: PipelineCell };

export const ConfigurationList: React.FC<{
  pipelines: Pipeline[];
  onUpdate: (pipeline: Pipeline) => void;
  onRemove: (pipeline: Pipeline) => void;
  onActiveChange: (pipeline: Pipeline, checked: boolean) => void;
}> = ({ pipelines, onRemove, onUpdate, onActiveChange }) => {
  const styles = useStyles2(getStyles);
  const columns: Array<Column<Pipeline>> = [
    {
      id: 'name',
      sortType: 'string',
      header: 'Name',
    },
    {
      id: 'matchingAttributes',
      header: 'Matching attributes',
      cell: MatchingAttributesCell,
    },
    {
      id: 'lastModified',
      header: 'Last modified',
      sortType: (r1: PipelineCell, r2: PipelineCell) => sortLastModified(r1.original, r2.original),
      cell: ({ row: { original: pipeline } }: PipelineCellProps) => (
        <span title={getFormattedModifiedDate(pipeline)}>{getFormattedTimeAgoDate(pipeline)}</span>
      ),
    },
    {
      id: 'enabled',
      header: 'Active',
      sortType: (r1: PipelineCell, r2: PipelineCell) => Number(r2.original.enabled) - Number(r1.original.enabled),
      cell: ({ row: { original: pipeline } }: PipelineCellProps) => (
        <Switch
          value={pipeline.enabled}
          onChange={(ev: React.FormEvent<HTMLInputElement>) => onActiveChange(pipeline, ev.currentTarget.checked)}
        />
      ),
    },
    {
      id: 'actions',
      header: '',
      cell: ({ row: { original: pipeline } }: PipelineCellProps) => (
        <div className={styles.editButtons}>
          <Button icon={'edit'} variant={'secondary'} fill="text" onClick={() => onUpdate(pipeline)} />
          <Button icon={'trash-alt'} variant={'secondary'} fill="text" onClick={() => onRemove(pipeline)} />
        </div>
      ),
    },
  ];

  return (
    <InteractiveTable<Pipeline> data={pipelines} getRowId={(pipeline: Pipeline) => pipeline.name} columns={columns} />
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    editButtons: css({
      display: 'flex',
      columnGap: theme.spacing(0.5),
      justifyContent: 'flex-end',
    }),
  };
}
