import React from 'react';

import { PluginPage } from '@grafana/runtime';

import { TabsPanel } from '@grafana-cloud/ui';
import { fleetTabs } from 'feature/common/components/Tabs/Tabs';
import { track } from 'feature/common/utils/track';
import { alloyIconOrange } from 'img';

export const ManageFleet: React.FC = () => {
  return (
    <PluginPage
      subTitle="Manage your collector inventory and push remote configurations"
      pageNav={{
        text: 'Fleet Management',
        img: alloyIconOrange,
        hideFromBreadcrumbs: true,
      }}
    >
      <TabsPanel
        tabs={fleetTabs.map((tab) => ({
          id: tab.id,
          label: tab.label,
          component: tab.component,
          active: tab.isDefault,
          onChangeTab: () => track('manage_fleet_tab_changed', { tab: tab.id }),
        }))}
      />
    </PluginPage>
  );
};
