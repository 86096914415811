import React, { useMemo, useState } from 'react';

import { SelectableValue } from '@grafana/data';
import { Select, Stack } from '@grafana/ui';

import { listCollectorAttributes, useQuery } from '@grafana-cloud/fleet-management-api';

type Props = {
  attributeName: string;
  onChangeAttributeValue: (attributeValue: string) => void;
  value: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const AttributeSelectValue = ({ attributeName, onChangeAttributeValue, value, ...divProps }: Props) => {
  const [customOptions, setCustomOptions] = useState<SelectableValue[]>([]);

  const { data, isLoading } = useQuery(listCollectorAttributes);

  const options: SelectableValue[] = useMemo(() => {
    const availableAttributes = data?.attributes || {};

    const hasAttributeName = availableAttributes.hasOwnProperty(attributeName);
    if (!hasAttributeName) {
      return [];
    }

    const selectedAttributeValues: SelectableValue[] = availableAttributes[attributeName].values.map((v) => ({
      label: v.value,
      value: v.value,
    }));
    return selectedAttributeValues;
  }, [attributeName, data?.attributes]);

  const updateValue = (v: SelectableValue<string>) => {
    onChangeAttributeValue(v.value ?? '');
  };

  return (
    <Stack direction="column" {...divProps}>
      <Select
        options={[...options, ...customOptions]}
        isLoading={isLoading}
        value={value}
        onChange={(v) => {
          updateValue(v);
        }}
        allowCustomValue={true}
        onCreateOption={(v: string) => {
          const customValue = { label: v, value: v };
          setCustomOptions((_customOptions) => [..._customOptions, customValue]);
          updateValue(customValue);
        }}
        placeholder="Select or type attribute value"
      />
    </Stack>
  );
};
