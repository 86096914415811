import { v4 as uuid } from 'uuid';

import { AttributeOperator, Attributes, SystemAttributeTableData } from 'feature/common/types/Attributes';

export function attributesToMatchers(attributes: Attributes): string[] {
  const matchers = [];
  for (const attribute of attributes) {
    if (!attribute.name) {
      continue;
    }
    matchers.push(`${attribute.name}${attribute.operator}${attribute.value}`);
  }
  return matchers;
}

export function matchersToAttributes(matchers: string[]): Attributes {
  return (
    matchers?.map((matcher) => {
      const [name, operator, value] = matcher.split(getMatcherRegexp());
      return { name, operator: operator as AttributeOperator, value, id: uuid() };
    }) || []
  );
}

function getMatcherRegexp() {
  return new RegExp(`(${Object.values(AttributeOperator).join('|')})`);
}

// Remove the 'collector.' prefix from the key in the attributes.
export const attributesToSystemAttributes = (attributes: { [key: string]: string }): SystemAttributeTableData[] => {
  return Object.keys(attributes).map((key) => {
    const trimmedKey = key.replace('collector.', '');
    return {
      id: trimmedKey,
      name: trimmedKey,
      value: attributes[key],
    };
  });
};
