import React, { useState } from 'react';

import { Space } from '@grafana/ui';

import { CollectorList } from 'feature/collector-list/components/CollectorList';
import { AttributesPanel } from 'feature/common/components/Attributes/AttributesPanel';
import { useAppDispatch, useAppSelector } from 'feature/common/store';
import { Attributes } from 'feature/common/types/Attributes';
import { attributesToMatchers, matchersToAttributes } from 'feature/common/utils/attributes';
import { onSetMatchers } from 'feature/remote-configuration/store/EditConfigurationSlice';

export const MatchToCollectors = () => {
  const matchers = useAppSelector((state) => state.editConfiguration.data.matchers);
  const [attributes, setAttributes] = useState(matchersToAttributes(matchers));
  const dispatch = useAppDispatch();

  function onAttributesChange(attributes: Attributes) {
    setAttributes(attributes);
    const newMatchers = attributesToMatchers(attributes);
    dispatch(onSetMatchers(newMatchers));
  }

  return (
    <>
      <h4>Add filters</h4>
      <AttributesPanel attributes={attributes} onAttributesChange={onAttributesChange} />
      <Space v={5} />
      <CollectorList allowSearch={false} allowSelect={false} matchers={attributesToMatchers(attributes)} />
    </>
  );
};
