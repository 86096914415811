import { createAction } from '@reduxjs/toolkit';
import { QueryCacheNotifyEvent } from '@tanstack/react-query';

import { Collector, Pipeline, listCollectors, listPipelines, queryClient } from '@grafana-cloud/fleet-management-api';

import { store } from './Store';

const listPipelinesSuccess = createAction<string[]>('queryClient/listPipelines/success');
const listCollectorsSuccess = createAction<string[]>('queryClient/listCollectors/success');

// action is not part of typing for some reason
type EventType = QueryCacheNotifyEvent & {
  action: {
    type: string;
  };
};

export class ReactQueryActionDispatcher {
  private unsubscribe: () => void;
  constructor() {
    this.unsubscribe = () => {};
  }
  init() {
    this.unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      const type = event.type;
      const actionType = (event as EventType)?.action?.type;
      const queryName = event.query.queryKey[1];
      const data = event.query.state.data;

      if (type === 'updated' && actionType === 'success') {
        switch (queryName) {
          case listPipelines.name:
            store.dispatch(listPipelinesSuccess(data.pipelines.map((pipeline: Pipeline) => pipeline.name)));
            break;
          case listCollectors.name:
            store.dispatch(listCollectorsSuccess(data.collectors.map((collector: Collector) => collector.name)));
            break;
          default:
            break;
        }
      }
    });
  }
  destroy() {
    this.unsubscribe();
  }
}
