import React from 'react';

import { AlloyInstallationTab } from 'feature/alloy/components/AlloyInstallationTab/AlloyInstallationTab';
import { AlloyMonitoringTab } from 'feature/alloy/components/AlloyMonitoringTab';
import { AlloyOverviewTab } from 'feature/alloy/components/AlloyOverviewTab';
import { CollectorDetailAttributes } from 'feature/collector-detail/components/CollectorDetailAttributes';
import { CollectorDetailHealth } from 'feature/collector-detail/components/CollectorDetailHealth';
import { CollectorDetailLogs } from 'feature/collector-detail/components/CollectorDetailLogs';
import { FleetInventory } from 'feature/fleet-inventory/pages/FleetInventory';
import { RemoteConfiguration } from 'feature/remote-configuration/components/list/RemoteConfiguration';

export enum CollectorTabs {
  Overview = 'overview',
  Installation = 'installation',
  Monitoring = 'monitoring',
}

enum FleetTabs {
  FleetInventory = 'fleet-inventory',
  RemoteConfiguration = 'remote-configuration',
}

enum CollectorDetailTabs {
  Health = 'health',
  Logs = 'logs',
  CollectorConfig = 'collector-config',
  Attributes = 'attributes',
}

export type TabsType = {
  id: CollectorTabs | FleetTabs | CollectorDetailTabs;
  label: string;
  component: React.JSX.Element;
  isVisible: boolean;
  isDefault?: boolean;
};

export const alloyTabs: TabsType[] = [
  {
    id: CollectorTabs.Overview,
    label: 'Overview',
    component: <AlloyOverviewTab />,
    isVisible: true,
  },
  {
    id: CollectorTabs.Installation,
    label: 'Installation',
    component: <AlloyInstallationTab />,
    isVisible: true,
    isDefault: true,
  },
  {
    id: CollectorTabs.Monitoring,
    label: 'Monitoring',
    component: <AlloyMonitoringTab />,
    isVisible: true,
  },
];

export const fleetTabs: TabsType[] = [
  {
    id: FleetTabs.FleetInventory,
    label: 'Inventory',
    component: <FleetInventory />,
    isVisible: true,
  },
  {
    id: FleetTabs.RemoteConfiguration,
    label: 'Remote configuration',
    component: <RemoteConfiguration />,
    isVisible: true,
  },
];

export const collectorDetailTabs: TabsType[] = [
  {
    id: CollectorDetailTabs.Health,
    label: 'Health',
    component: <CollectorDetailHealth />,
    isVisible: true,
  },
  {
    id: CollectorDetailTabs.Logs,
    label: 'Logs',
    component: <CollectorDetailLogs />,
    isVisible: true,
  },
  {
    id: CollectorDetailTabs.Attributes,
    label: 'Attributes',
    component: <CollectorDetailAttributes />,
    isVisible: true,
  },
];
