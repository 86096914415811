import { createContext } from 'react';

import { AppJSONData } from 'feature/common/types/AppJSONData';

interface AppContextData extends AppJSONData {}

export const AppContext = createContext<AppContextData>({
  stackId: '',
  orgId: '',
  region: '',
  environment: 'dev',
  agmClusterUrl: '',
});
