import { css } from '@emotion/css';
import React from 'react';
import SVG from 'react-inlinesvg';

import { GrafanaTheme2 } from '@grafana/data';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { CollectorTabs } from 'feature/common/components/Tabs/Tabs';
import { track } from 'feature/common/utils/track';
import { prefixRoute } from 'feature/home/utils/routing';
import { alloyIconOrange } from 'img';

export const HomeStartSection: React.FC = () => {
  const styles = useStyles2(getStyles);

  return (
    <section className={styles.section}>
      <div className={styles.card}>
        <div className={styles.subSectionCentered}>
          <SVG className={styles.logo} src={alloyIconOrange} />
          <h3>Get started with Grafana Alloy</h3>
          <p className={styles.secondaryText}>Install and configure Grafana Alloy</p>
          <LinkButton
            href={prefixRoute('alloy/' + CollectorTabs.Installation)}
            onClick={() =>
              track('install_button_clicked', {
                location: 'landing_page',
              })
            }
          >
            Install Grafana Alloy
          </LinkButton>
        </div>
        <div className={styles.subSection}>
          <h4>About Grafana Alloy</h4>
          <p className={styles.secondaryText}>
            Grafana Alloy collects observability data and sends it to Grafana Cloud.
          </p>
          <p className={styles.secondaryText}>
            You can deploy Alloy anywhere within your IT infrastructure and you can pair it with your Grafana LGTM
            stack, a telemetry backend from Grafana Cloud, or any other compatible backend from any other vendor.
          </p>
          <p className={styles.secondaryText}>
            Alloy is flexible, and you can easily configure it to fit your needs in on-prem, cloud-only, or a mix of
            both.
          </p>
        </div>
      </div>
    </section>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  section: css({
    backgroundColor: theme.colors.background.secondary,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  }),
  card: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '@media screen and (max-width: 1300px)': {
      gridTemplateColumns: '1fr',
    },
    gap: theme.spacing(10),
    maxWidth: 1000,
    margin: '0 auto',
  }),
  subSectionCentered: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  }),
  subSection: css({
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 1300px)': {
      alignItems: 'center',
      maxWidth: 400,
      margin: 'auto',
    },
  }),
  logo: css({
    width: 54,
    height: 54,
    marginBottom: '1rem',
  }),
  secondaryText: css({
    color: theme.colors.text.secondary,
  }),
});
