import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';

import { collectorIdSearchParam } from 'feature/collector-list/const/searchParams';

type Props = {
  children: React.ReactNode;
  collectorId: string;
};

export const CellWrapper = ({ children, collectorId }: Props) => {
  const styles = useStyles2(getStyles);

  const onClick = () => {
    locationService.partial({ [collectorIdSearchParam]: collectorId });
  };

  return (
    <div className={styles.button} onClick={onClick}>
      {children}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  button: css({
    padding: theme.spacing(1),
    cursor: 'pointer',
  }),
});
