import { css } from '@emotion/css';
import React from 'react';

import { Label, Stack, useStyles2 } from '@grafana/ui';

export const AttributeSelectLabels = () => {
  const styles = useStyles2(getStyles);

  return (
    <Stack gap={0}>
      <Label className={styles.halfWidth}>Key</Label>
      <Label className={styles.halfWidth}>Value</Label>
    </Stack>
  );
};

const getStyles = () => ({
  halfWidth: css({
    width: '50%',
    maxWidth: '332px',
  }),
});
