import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import { usePluginContext } from '@grafana/data';

import { AccessPoliciesProvider, RealmTypes } from '@grafana-cloud/access-policies';
import { CollectorProvider } from '@grafana-cloud/collector';
import { store } from 'feature/common/store';
import { AppApiContext } from 'feature/common/store/AppApiContext';
import { ReactQueryActionDispatcher } from 'feature/common/store/ReactQueryActionDispatcher';
import { AppJSONData } from 'feature/common/types/AppJSONData';
import { track } from 'feature/common/utils/track';

import { ApiProviders } from './ApiProviders';
import { AppContext } from './AppContext';

const reactQueryActionDispatcher = new ReactQueryActionDispatcher();

export function AppProviders({ children }: { children: React.JSX.Element }) {
  const { meta } = usePluginContext();
  const jsonData = meta?.jsonData as AppJSONData;
  const stackId = jsonData?.['stackId'] ?? '';
  const region = jsonData?.['region'] ?? '';
  const environment = jsonData?.['environment'] ?? '';
  const orgId = jsonData?.['orgId'] ?? '';
  const agmClusterUrl = jsonData?.['agmClusterUrl'] ?? '';

  useEffect(() => {
    reactQueryActionDispatcher.init();
    return () => {
      reactQueryActionDispatcher.destroy();
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        stackId,
        region,
        environment,
        orgId,
        agmClusterUrl,
      }}
    >
      <Provider store={store} context={AppApiContext}>
        <ApiProviders>
          <CollectorProvider
            config={{
              stackId,
              track,
            }}
          >
            <AccessPoliciesProvider
              config={{
                realm: { realmType: RealmTypes.STACK, realmIdentifier: String(stackId) },
                region,
                track,
              }}
            >
              {children}
            </AccessPoliciesProvider>
          </CollectorProvider>
        </ApiProviders>
      </Provider>
    </AppContext.Provider>
  );
}
