import React from 'react';

import { CellWrapper } from './CellWrapper';
import { CollectorCellProps } from './TableColumns';

export const NameCell = ({
  row: {
    original: { id: collectorId, name: collectorName },
  },
}: CollectorCellProps) => {
  // Add a UTF whitespace character to prevent the cell from collapsing when empty.
  // If the cell is empty, the cell will have no height and wont act as clickable.
  return <CellWrapper collectorId={collectorId}>{collectorName || '\u00A0'}</CellWrapper>;
};
