import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppRootProps, PluginContextProvider } from '@grafana/data';

import { Alloy } from 'feature/alloy/components/Alloy';
import { alloyTabs, fleetTabs } from 'feature/common/components/Tabs/Tabs';
import { AppJSONData } from 'feature/common/types/AppJSONData';
import { isFleetManagementEnabled } from 'feature/common/utils/featureToggles';
import { ManageFleet } from 'feature/fleet-inventory/pages/ManageFleet';
import { Home } from 'feature/home/components/Home';
import { CreateConfiguration } from 'feature/remote-configuration/pages/CreateConfiguration';
import { UpdateConfiguration } from 'feature/remote-configuration/pages/UpdateConfiguration';

import { AppProviders } from './AppProviders';

export function App(props: AppRootProps<AppJSONData>) {
  return (
    <PluginContextProvider meta={props.meta}>
      <AppProviders>
        <Routes>
          {alloyTabs.map((tab) => (
            <Route key={tab.id} path={'alloy/' + tab.id} element={<Alloy />} />
          ))}
          <Route path={'alloy'} element={<Alloy />} />
          {isFleetManagementEnabled() && (
            <>
              {fleetTabs.map((tab) => (
                <Route key={tab.id} path={'fleet-management/' + tab.id} element={<ManageFleet />} />
              ))}
              <Route path={'fleet-management'} element={<ManageFleet />}></Route>
              <Route path={'fleet-management/create-configuration'} element={<CreateConfiguration />} />
              <Route path={'fleet-management/update-configuration/:name'} element={<UpdateConfiguration />} />
            </>
          )}
          <Route path="" element={<Home />} />
        </Routes>
      </AppProviders>
    </PluginContextProvider>
  );
}
